
<!-- Default list-->

<ul *ngIf="el.classes.includes('default-list')" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}">
 <li *ngFor="let li of el.value | eval : 'false'"><span [innerHTML]="li"></span></li>
</ul>

<!-- Custom - About Us list -->

<div *ngIf="el.classes.includes('custom-list')" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class=" here {{el.classes}}" #boxlist>
 <div *ngFor="rows let li of el.value | eval : 'false'" class="row table marg-vert-40 scroll-in scroll-once" >
   <div class="col-2 col-1 box-wrap el-box rel text-left v-align">
         <h1 class="no-padding outline-text-primary">{{li.title}}</h1>
   </div>
   <div class="col-2 col-2 box-wrap el-box rel text-left v-align">
         <p>{{li.desc}}</p>
   </div>
 </div>
</div>

<!-- Custom - Reveal list -->

<div *ngIf="el.classes.includes('reveal-list')" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class=" here {{el.classes}}" #boxlist>
<div *ngFor="rows let li of el.value | eval : 'false'" class="row table marg-vert-40 scroll-in scroll-once" >
  <div class="row rel text-left title-panel" (click)="toggleReveal(reveal)">
        <h3 class="no-padding text-primary">{{li.title}}</h3>
  </div>
  <div class="row rel text-left desc-panel" #reveal>
        <p>{{li.desc}}</p>
  </div>
</div>
</div>

<!-- Box list -->

<div *ngIf="el.elclasses.includes('box-list')" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.elclasses}}" #boxlist>
 <div *ngFor="let li of el.value | eval : 'false'" class="{{el.classes}}">
   <div class="box-wrap el-box rel">
         <div class="box">
             <div class="box-img-wrap" #boxparent><img *ngIf="li.image.length" src="{{li.image}}" alt="{{li.title}}" #imge class="box-img" (window:resize)="lib.onResizeFitChildEl(imge,boxparent)" /></div>
             <div class="box-title"><h3 class="italic bold">{{li.title}}</h3></div>
             <div class="box-desc"><p>{{li.desc}}</p></div>
             <div *ngIf="li.link" class="box-link"><a (click)="parent.changePage(li.link,0,[],$event, li.get)" >View Details</a></div>
         </div>
     </div>
 </div>
</div>

<!-- Icon list -->

<ul *ngIf="el.classes.includes('icon-list')" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}">
 <li *ngFor="let li of el.value | eval : 'false'" [ngClass]="lib.isProperty(li,'link') ? 'icon-btn' : ''" class="{{el.innerclasses}} scroll-in scroll-once">
   <div class="wrap {{li.classes}}">
     <a *ngIf="lib.isProperty(li,'link')" href="{{li.link}}" class="btn-overlay"></a>
     <div class="boxwrap">
       <div class="outer">
         <div class="inner">
           <div *ngIf="li.value &amp;&amp; li.value.length" [innerHTML]="li.value" class="content center v-align"></div>
           <img *ngIf="li.icon &amp;&amp; li.icon.length" src="assets/icons/{{li.icon}}.svg" width="120" height="120" class="block center pad-20" />
           <h4 *ngIf="li.title &amp;&amp; li.title.length" [innerHTML]="li.title" class="title content center"></h4>
           <div *ngIf="li.desc &amp;&amp; li.desc.length" [innerHTML]="li.desc" class="desc content center v-align"></div>
         </div>
       </div>
     </div>
   </div>
 </li>
</ul>

<!-- Custom - Client list -->

<ul *ngIf="el.classes.includes('client-list')" class="list-no-style list-horiz maxwidth-700 center">
<li *ngFor="let li of el.value | eval : 'false'" [ngClass]="lib.isProperty(li,'link') ? 'icon-btn' : ''" class="{{el.innerclasses}} scroll-in scroll-once">
  <img src="assets/images/clients/{{li}}.png" width="100" height="100" class="inline-block" />
</li>
</ul>

<!-- Info list -->

<ul *ngIf="el.classes.includes('info-list')" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}} list-no-style spread-even-horiz-mobile  wrap" #infolist ([ngModel])="infolist">

 <li *ngFor="let item of el.value | eval : 'false'; let idx = index;" class="inline-block rel float-left scroll-in scroll-fade scroll-once"  [ngClass]="el.classes.includes('large') ? 'marg-20' : ''">
   <div class="{{item.class}} pointer white white-hover center text-white-section" [ngClass]="[infoidx == idx ? 'on' : '', el.classes.includes('large') ? 'large' : '', el.classes.includes('circles') ? 'cta-circle' : '', el.classes.includes('squares') ? 'cta-square' : '' ]" (click)="showInfoWindow(infolist,item,idx)">
     <div class="cta-wrap">
       <div *ngIf="item.image" class="box-img-wrap" #infoparent><img src="{{item.image}}" alt="{{item.title}}" #imge class="box-img" (window:resize)="lib.onResizeFitChildEl(imge,infoparent)" /></div>
        <h3 *ngIf="el.classes.includes('large')" [ngClass]="item.image ? 'inside' : ''">{{item.title}}</h3>
        <div class="title-small" *ngIf="!el.classes.includes('large')" [ngClass]="item.image ? 'inside' : ''">{{item.title}}</div>
        <p *ngIf="item.subtitle"  [ngClass]="item.image ? 'inside' : ''">{{item.subtitle}}</p>
     </div>
   </div>

   <div class="revealwindow mobile-show" [ngClass]="infoidx == idx ? 'on' : ''">
     <div class="block fill-height" style="width:100%">
       <div class=" fill-height full-width v-align-parent wrap text-darkgrey-section">
         <div class="full-width v-align-parent wrap">
             <div class="revealcontent table">

               <div class="revealdesc text-left v-align-top inline-table pad-vert-20" [innerHTML]="item.desc"></div><br />
             </div>
             <div class="revealcontent table">
               <ul class="sublist text-left list-no-style">
                 <li class="pointer bold cta" (click)="selectSubList(itm,sid)" *ngFor="let itm of item.sublist; let sid = index" [ngClass]="sid == subid ? 'on': ''">{{itm.title}} ></li>
               </ul>
               <div [ngClass]="sublist.length == '' ? 'off' : 'fade-in'" class="sublist-content text-left pad-vert-20" [innerHTML]="sublist"></div>
             </div>
         </div>
      </div>
     </div>
   </div>



 </li>
</ul>

<!--Info window -->

<!-- <div [ngClass]="showinfowindow ? 'show2' : 'hide2'" class="infowindow"> -->
<div [ngClass]="showinfowindow ? 'fade-in' : ''" *ngIf="el.classes.includes('info-list')" class="infowindow mobile-hide">
<div class="block fill-height" style="width:100%">

  <div class=" fill-height full-width v-align-parent wrap text-darkgrey-section">
    <div class="full-width v-align-parent wrap">

        <div class="infocontent table">
          <div class="infodesc text-left v-align-top inline-table pad-40" [innerHTML]="infoitem.desc"></div><br />
        </div>

        <div class="infocontent table">
          <div *ngFor="let itm of infoitem.sublist;let sid = index" class="text-left">
            <h3>{{itm.title}}</h3>
            <p class="text-darkgrey" [innerHTML]="itm.desc"></p>
          </div>

        </div>
    </div>
 </div>

</div>
</div>


<!-- Custom - Profile List-->

<ul *ngIf="el.classes.includes('profile-list')" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}} list-no-style spread-even-horiz-mobile wrap" #profilelist ([ngModel])="profilelist" (window:resize)="showProfileDesc()">

<li *ngFor="let item of el.value | eval : 'false'; let idx = index;" class="inline-block rel float-left scroll-in scroll-fade scroll-once"  [ngClass]="el.classes.includes('large') ? '' : ''">
 <div class="{{item.class}} pointer white white-hover center" [ngClass]="[profileidx == idx ? 'on' : '', el.classes.includes('large') ? 'large' : '', el.classes.includes('circles') ? 'cta-circle' : '', el.classes.includes('squares') ? 'cta-square' : '' ]" (click)="showProfileWindow(infolist,item,idx,reveal)">
   <div class="cta-wrap">
      <h3 *ngIf="el.classes.includes('large')" [ngClass]="item.image ? 'inside' : ''">{{item.title}}</h3>
      <div class="title-small" *ngIf="!el.classes.includes('large')" [ngClass]="item.image ? 'inside' : ''">{{item.title}}</div>
      <p *ngIf="item.subtitle"  [ngClass]="item.image ? 'inside' : ''">{{item.subtitle}}</p>
      <img src="assets/icons/{{profileidx == idx ? 'arrow-up-secondary': 'arrow-down-white'}}.svg" class="toggle-profile-icon" />
      <div *ngIf="item.image" class="box-img-wrap" #infoparent>
        <div class="gradient2 abs full-width" [ngClass]="profileidx == idx ? 'on' : ''"></div>
        <div class="gradient abs full-width" [ngClass]="profileidx == idx ? '' : 'on'"></div>
        <img src="{{item.image}}" alt="{{item.title}}" #imge class="box-img" (window:resize)="lib.onResizeFitChildEl(imge,infoparent)" />
      </div>
   </div>
 </div>

 <div class="revealwindow" #reveal [ngClass]="profileidx == idx ? 'on' : ''">
   <div class="block fill-height" style="width:100%">

     <div class="revealcontent inline-block">
       <div class="revealdesc text-left" [innerHTML]="item.desc"></div><br />
     </div>

   </div>
 </div>

</li>
</ul>



<!--Tag List-->

<div *ngIf="el.classes.includes('tag-list')" class="tag-list-wrap">
<div class="filter-tags-controls width-70 center rel table">
  <p class="inline-block"> <span class="pointer filter-btn bold"  [ngClass]="filterTag == 'service' ? 'text-secondary filter-on':''" (click)="toggleTagList('service')">Filter by service</span>  &nbsp; <span class="pointer filter-btn bold" [ngClass]="filterTag == 'sector' ? 'text-secondary filter-on': ''" (click)="toggleTagList('sector')">Filter by sector</span> </p>
</div>

<div *ngFor="let arr of el.value  | format : 'json'">
  <div [ngSwitch]="filterTag" class="element-wrapper">
    <ul  *ngSwitchCase="'sector'" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="tag-list {{el.classes}}">
     <div *ngFor="let li of arr.sector | eval : 'false'" class="cta-circle cta pointer white white-hover center text-white-section" [ngClass]="parent.tagfocus == li ? 'on' : ''" (click)="filterTags(li)">
       <div class="cta-wrap">
         <div class="title">{{li}}</div>
       </div>
     </div>
     <div class="cta-circle cta pointer white-hover center text-primary-section background-secondary reset-cta" (click)="filterTags()">
       <div class="cta-wrap">
         <div class="title">RESET</div>
       </div>
     </div>
   </ul>

   <ul  *ngSwitchCase="'service'" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="tag-list {{el.classes}}">
    <div *ngFor="let li of arr.service | eval : 'false'" class="cta-circle cta pointer white white-hover center text-white-section" [ngClass]="parent.tagfocus == li ? 'on' : ''" (click)="filterTags(li)">
      <div class="cta-wrap">
        <div class="title">{{li}}</div>
      </div>
    </div>
    <div class="cta-circle cta pointer white-hover center text-primary-section background-secondary reset-cta" (click)="filterTags()">
      <div class="cta-wrap">
        <div class="title">RESET</div>
      </div>
    </div>
  </ul>
</div>
</div>
</div>
