<!-- <div [@modal] *ngIf="visible" class="modal"> -->
<div class="modal-panel" [ngClass]="(visible ? 'modal-on': '') + ' ' +  (tmp_close ? 'modal-off': '')" >

  <div *ngIf="visible" class="modal">

    <button *ngIf="closable" (click)="close()" aria-label="Close" class="modal__close-btn float-right">X Close</button>

    <div *ngIf="show_nav" class="modal__prev-btn" (click)="prev()"><img src="./assets/images/modal_prev.png" /></div>

    <!-- <ng-content></ng-content> -->
    <div class="modal_content_wrap">
      <h2>{{modalTitle}}</h2>
      <div [innerHTML]="modalContent" class="modal_content"></div>
    </div>

    <div *ngIf="show_nav"  class="modal__next-btn" (click)="next()"><img src="./assets/images/modal_next.png" /></div>

    <button *ngIf="cta" (click)="doCTA()" aria-label="CTA" class="modal__cta-btn cta block center background-primary text-white pad-20">{{ cta == true ? 'Enquire now' : cta }}</button>

  </div>
  <div *ngIf="visible" class="overlay" (click)="close()"></div>

</div>
