import { Component, OnInit, Input, ElementRef, Output, OnChanges, EventEmitter, SimpleChanges, HostBinding, Directive, Injectable, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';

import Parallax from 'parallax-js';
//https://github.com/mrdoob/three.js/blob/master/examples/webgl_points_billboards.html


import * as THREE from 'three';

import Stats from 'three/examples/jsm/libs/stats.module.js';

import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';

@Directive({
  selector: '[dataParallaxDir]'
})
export class ParallaxDir {
  @Input('dataParallaxDir') attributes: any;
  camera:any;
  scene:any;
  renderer:any;
  stats:any;
  material:any;
  mouseX:number = 0;
  mouseY:number = 0;
  loaded:boolean = false;
  windowHalfX:number = window.innerWidth / 2;
  windowHalfY:number = window.innerHeight / 2;


  constructor() {
      var camera, scene, renderer, stats, material;
      var mouseX = 0, mouseY = 0;

      var windowHalfX = window.innerWidth / 2;
      var windowHalfY = window.innerHeight / 2;

      setTimeout(()=>{
        console.log('datatest directive');
      },);
   }

   async ngOnInit(){
        console.log('starting dots');
        setTimeout(async ()=>{
          await this.init();
        },);
    }

		async	init() {

        //if(this.loaded == true) return false;

        //var stage = document.getElementById('parallax-scene');
        //stage.innerHTML = '';

        //if(!stage) console.log('no parallax stage found');

        var stages = document.querySelectorAll('.parallax-scene');

        console.log('stages: ' + stages.length);

        stages.forEach(stage => {

          stage.innerHTML = '';

          var dotcount = 20;

          for ( var i = 0; i < dotcount; i ++ ) {

  					var x = 2000 * Math.random() - 1000;
  					var y = 2000 * Math.random() - 1000;
  					var z = 2000 * Math.random() - 1000;

            let height = Math.floor(Math.random() * 100) + 25; //random number from  50 to 100;
            let pos_y = (Math.floor(Math.random() * 100) + 25) + '%' ;
            let pos_x = (Math.floor(Math.random() * 100) + 25) + '%';
            let randomdot = (Math.floor(Math.random() * 20) + 1);

            var dot = document.createElement("DIV");
            const depth = document.createAttribute('data-depth');
            // const posx = document.createAttribute('data-origin-x');
            // const posy = document.createAttribute('data-origin-y');
            // const scalex = document.createAttribute('data-scalar-x');
            // const scaley = document.createAttribute('data-scalar-y');
            //
            depth.value = i.toString();
            // posx.value = pos_x.toString();
            // posy.value = pos_y.toString();
            // scalex.value = scale.toString();

            dot.setAttributeNode(depth);

            //let dottypes = ['dot1','dot2','dot3','dot4','dot5'];

            //const randomDotType = dottypes[Math.floor(Math.random() * dottypes.length)];
            dot.classList.add('obj');
            dot.classList.add('dot'+i);

            //dot.style.setProperty('height', height.toString());
            //dot.style.setProperty('left', pos_x);
            //dot.style.setProperty('top', pos_y);

  					//vertices.push( x, y, z );
            stage.appendChild( dot );

          }

          var dotcount = 3;

          for ( var i = 0; i < dotcount; i ++ ) {

            var x = 2000 * Math.random() - 1000;
  					var y = 2000 * Math.random() - 1000;
  					var z = 2000 * Math.random() - 1000;

            let height = Math.floor(Math.random() * 100) + 80; //random number from  50 to 100;
            let pos_y = (Math.floor(Math.random() * 800) + 400) + 'px' ;
            let pos_x = (Math.floor(Math.random() * 800) + 400) + 'px';
            let randomdot = (Math.floor(Math.random() * 20) + 1);

            var dot = document.createElement("DIV");
            const depth = document.createAttribute('data-depth');
            //
            depth.value = i.toString();

            dot.setAttributeNode(depth);

            dot.classList.add('obj');
            dot.classList.add('dot-ampersand'+i);

            console.log(pos_x);
            //dot.style.left = "100px";
            // dot.style.setProperty('margin-left', pos_x);
            // dot.style.setProperty('margin-top', pos_y);

            stage.appendChild( dot );

          }


          var parallaxInstance = new Parallax(stage, {
            relativeInput: true
          });

        });

        this.loaded = true;

        //stage.appendChild( this.renderer.domElement );
			}
}
