<!--loader-->
<img *ngIf="parent.gridLoaded == false"  src="./assets/icons/loader.svg" width="50" height="50" class="block center" />




<!-- hidden images loader -->

<ul id="masonry-grid-loader-{{suffix}}" class="{{el.classes}} hide" #masonry (window:resize)="resizeMasonry()">
  <li *ngFor="let li of list | eval : 'false'" [ngClass]="lib.isProperty(li,'link') ? 'icon-btn' : ''" class="{{li.classes}} {{el.innerclasses}}">
    <div class="wrap">
      <div *ngIf="lib.isProperty(li,'link')" class="btn-overlay"></div>
      <div class="boxwrap">
        <div class="outer">
          <div class="inner">
             <div *ngIf="li.image &amp;&amp; li.image.length" class="image-wrap">
               <img src="{{li.image}}" [attr.data-image]="li.image" class="image"  [attr.data-desc]="li.desc ? li.desc : ''" [attr.data-title]="li.title ? li.title : ''" [attr.data-content]="li.content ? li.content : ''" [attr.data-link]="li.link ? li.link : ''" [attr.data-tags]="li.tags ? li.tags : ''" [attr.data-animation]="li.animation ? li.animation : ''" [attr.data-video]="li.video ? li.video : ''" [attr.data-videocontrols]="li.videocontrols ? li.videocontrols : false" />
             </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>

<!-- masonry grid -->
<div id="masonry-grid-{{suffix}}" class="masonry-grid {{suffix}}">
  <div *ngFor="let row of grid; let ridx = index" class="row">

    <div *ngFor="let col of row.cols; let cidx = index" class="col cols-{{row.cols.length}}">

      <div [ngSwitch]="col.coltype" class="col-wrapper {{col.coltype}}">

          <div *ngSwitchCase="'horiz'" class="wrap scroll-in scroll-once fill-height-table" (click)="col.images[0].link !== '' ? doCallback('changePage',[col.images[0].link,0,[],$event,false]) : false">

            <div *ngIf="col.images[0].title || col.images[0].content" class="content">
              <div *ngIf="col.images[0].title" class="content-title" [innerHTML]="col.images[0].title"></div>
              <div *ngIf="col.images[0].desc" class="content-desc" [innerHTML]="col.images[0].desc"></div>
              <div *ngIf="col.images[0].content" class="content-content" [innerHTML]="col.images[0].content"></div>
            </div>
            <div *ngIf="col.images[0].src.indexOf('OFF') === -1 && col.images[0].src != ''" class="image-wrap">
              <img src="{{col.images[0].src}}" class="image" />
              <div class="cover"></div>
            </div>
            <div *ngIf="col.images[0].src.indexOf('OFF') !== -1 && col.images[0].animation == false && col.images[0].video == false" class="image-wrap no-image">
              <div class="cover background-secondary"></div>
            </div>
            <div *ngIf="col.images[0].src.indexOf('OFF') !== -1 && col.images[0].animation" class="image-wrap no-image animation-wrap">
              <div>
              <iframe [src]="col.images[0].animation | safe : 'url'" class="animation-frame"></iframe>
              </div>
            </div>
            <div *ngIf="(col.images[0].src.indexOf('OFF') !== -1 || col.images[0].src.indexOf('VIDEO') !== -1) && col.images[0].video" class="image-wrap no-image video-wrap">
              <div>
                <video autoplay *ngIf="col.images[0].videocontrols == false" [src]="col.images[0].video | safe : 'url'" class="video-frame"></video>

                <video autoplay controls *ngIf="col.images[0].videocontrols == true" [src]="col.images[0].video | safe : 'url'" class="video-frame"></video>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'fullsquare'" class="wrap scroll-in scroll-once"  (click)="col.images[0].link !== '' ? doCallback('changePage',[col.images[0].link,0,[],$event,false]): false">
            <div *ngIf="col.images[0].title" class="content">
              <div *ngIf="col.images[0].title" class="content-title" [innerHTML]="col.images[0].title"></div>
              <div *ngIf="col.images[0].desc" class="content-desc" [innerHTML]="col.images[0].desc"></div>
            </div>
            <div class="image-wrap">
              <img src="{{col.images[0].src}}" class="image" />
              <div class="cover"></div>
            </div>
          </div>

          <div *ngSwitchCase="'portrait'" class="wrap scroll-in scroll-once"  (click)="col.images[0].link !== '' ? doCallback('changePage',[col.images[0].link,0,[],$event,false]) : false">
            <div *ngIf="col.images[0].title" class="content">
              <div *ngIf="col.images[0].title" class="content-title" [innerHTML]="col.images[0].title"></div>
              <div *ngIf="col.images[0].desc" class="content-desc" [innerHTML]="col.images[0].desc"></div>
            </div>
            <div class="image-wrap">
              <img src="{{col.images[0].src}}" class="image" />
              <div class="cover"></div>
            </div>
          </div>

          <div *ngSwitchCase="'related'" class="wrap scroll-in scroll-once"  (click)="col.images[0].link !== '' ? doCallback('changePage',[col.images[0].link,0,[],$event,false]) : false">
            <div *ngIf="col.images[0].title" class="content v-align2">
              <div *ngIf="col.images[0].title" class="content-title text-center" [innerHTML]="col.images[0].title"></div>
              <div *ngIf="col.images[0].desc" class="content-desc" [innerHTML]="col.images[0].desc"></div>
            </div>
            <div class="image-wrap">
              <img src="{{col.images[0].src}}" class="image" />
              <div class="cover"></div>
            </div>
          </div>

          <div *ngSwitchCase="'third'" class="wrap scroll-in scroll-once"  (click)="col.images[0].link !== '' ? doCallback('changePage',[col.images[0].link,0,[],$event,false]) : false">
            <div *ngIf="col.images[0].title" class="content">
              <div *ngIf="col.images[0].title" class="content-title" [innerHTML]="col.images[0].title"></div>
              <div *ngIf="col.images[0].desc" class="content-desc" [innerHTML]="col.images[0].desc"></div>
            </div>
            <div class="image-wrap">
              <div *ngIf="col.images[0].src.includes('html')" class="iframe-cover" (click)="col.images[0].link !== '' ? doCallback('changePage',[col.images[0].link,0,[],$event,false]) : false"></div>
              <iframe *ngIf="col.images[0].src.includes('html')" [src]="col.images[0].src | safe : 'url'" class="animationframeblog pointer" (click)="doCallback('changePage',[col.images[0].link,0,[],$event,false])" width="230" height="230" ></iframe>
              <img *ngIf="!col.images[0].src.includes('html')" src="{{col.images[0].src}}" class="image" />
              <div class="cover"></div>
            </div>
          </div>

          <div *ngSwitchCase="'halfsquare'"  (click)="col.images[0].link !== '' ? doCallback('changePage',[col.images[0].link,0,[],$event,false]) : false">

            <div *ngFor="let image of col.images" class="wrap image-wrap scroll-in scroll-once">
              <div *ngIf="col.images[0].title" class="content">
                <div *ngIf="image.title" class="content-title" [innerHTML]="image.title"></div>
                <div *ngIf="image.desc" class="content-desc" [innerHTML]="image.desc"></div>
              </div>
              <img src="{{image.src}}" class="image" />
              <div class="cover"></div>
            </div>
          </div>

          <div *ngSwitchCase="'quartersquare'">

            <div *ngFor="let image of col.images" class="wrap image-wrap scroll-in scroll-once" (click)="col.images[0].link !== '' ? doCallback('changePage',[image.link,0,[],$event,false]) : false">
              <div *ngIf="col.images[0].title" class="content">
                <div *ngIf="image.title" class="content-title" [innerHTML]="image.title"></div>
                <div *ngIf="image.desc" class="content-desc" [innerHTML]="image.desc"></div>
              </div>
              <img src="{{image.src}}" class="image" />
              <div class="cover"></div>
            </div>
          </div>
      </div>

    </div>
  </div>
</div>

<!-- load more button -->
<!-- <div id="loadmore" (click)="loadMore()" *ngIf="parent.gridLoaded && !loadFinished && parent.setPage !== 'home' && !related" style="position:relative;margin-top:40px!important;padding: 10px!important;" class="pointer pad-40 cta-circle center primary primary-hover"><div class="cta-wrap v-align">Load more ></div></div>
<div id="loadmore" (click)="parent.changePage('our-work')" *ngIf="parent.gridLoaded && !loadFinished && parent.setPage == 'home'" style="position:relative;margin-top:40px!important;padding: 10px!important;" class="pointer pad-40 cta-circle center primary primary-hover"><div class="cta-wrap v-align">View more ></div></div> -->


<div id="loadmore" (click)="loadMore()" *ngIf="parent.gridLoaded && !loadFinished && parent.setPage !== 'home' && !related" style="position:relative;margin-top:40px!important;padding: 10px!important;" class="pointer table pad-40 center primary primary-hover"><img src="./assets/icons/arrow-down-primary.svg" class="block" width="30" height="30" /></div>
<div id="loadmore" (click)="parent.changePage('our-work')" *ngIf="parent.gridLoaded && parent.setPage == 'home'" style="position:relative;margin-top:40px!important;padding: 10px!important;" class="pointer table pad-40 center primary primary-hover"><img src="./assets/icons/arrow-down-white.svg" class="block rotate-90 center" width="30" height="30" /><br><div class="cta-wrap center text-white-section"><a href="our-work" style="text-decoration:none">See more work</a></div></div>
