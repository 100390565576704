import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { LottieModule } from 'ngx-lottie';
//import player from 'lottie-web';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AliasPipe } from '../pipes/alias.pipe/alias.pipe';
import { SafePipe } from '../pipes/safe.pipe/safe.pipe';
import { EvalPipe } from '../pipes/eval.pipe/eval.pipe';
import { KeysPipe } from '../pipes/keys.pipe/keys.pipe';
import { FormatPipe } from '../pipes/format.pipe/format.pipe';

import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ChildTemplateComponent } from '../components/ChildTemplateComp/childtemplate.component';
import { CaseStudyTemplateComponent } from '../components/CaseStudyTemplateComp/casestudytemplate.component';
import { NewsTemplateComponent } from '../components/NewsTemplateComp/newstemplate.component';
import { ContactComponent } from '../components/ContactComp/contact.component';


import { ModalComponent } from '../components/ModalComp/modal.component';
import { FeedComponent } from '../components/FeedComp/feed.component';
import { SearchComponent } from '../components/SearchComp/search.component';
import { SideNavComponent } from '../components/SideNavComp/sidenav.component';
import { SocialShareComponent } from '../components/SocialShareComp/socialshare.component';
import { MasonryGridComponent } from '../components/MasonryGridComp/masonrygrid.component';
import { CarouselComponent } from '../components/CarouselComp/carousel.component';
import { ListComponent } from '../components/ListComp/list.component';

import { AttrDir } from '../directives/attributes.directive';
import { ParallaxDir } from '../directives/parallax.directive';
import { ProgressCircleDir } from '../directives/progresscircle.directive';

import { CookieService } from 'ngx-cookie-service';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    AliasPipe,
    SafePipe,
    EvalPipe,
    KeysPipe,
    FormatPipe,
    ChildTemplateComponent,
    ModalComponent,
    FeedComponent,
    SearchComponent,
    MasonryGridComponent,
    AttrDir,
    ParallaxDir,
    ProgressCircleDir,
    SideNavComponent,
    SocialShareComponent,
    CaseStudyTemplateComponent,
    NewsTemplateComponent,
    CarouselComponent,
    ContactComponent,
    ListComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [HttpClient, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
