import {Pipe} from '@angular/core';

@Pipe({
  name: 'keys',
  pure: true
})
export class KeysPipe {
  transform(value, args:any = 'undefined') {

      let keys = [];
      //let disallowed = ['id','lid','eid'];
      let disallowed = [];
      if(typeof args !== 'undefined'){
        disallowed = args[0];
      }

      for (let key in value) {
          if(disallowed.indexOf(key) == -1){
              keys.push({key: key, value: value[key]});
          }
      }

      return keys;

  }
}
