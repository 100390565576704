<div class="carousel car-{{theme}} {{classes}}" (mouseenter)="hoverItem()" (mouseleave)="leaveItem()">

  <!-- Navigation -->

  <div class="carousel-navigation" *ngIf="items.length > 1 && navigation">
    <div class="carousel-nav-prev carousel-nav-btn flip" (click)="navigateItem(false)">
      <svg xmlns="http://www.w3.org/2000/svg" width="64.414" height="48.828" viewBox="0 0 64.414 48.828">
        <g id="Group_14" data-name="Group 14" transform="translate(285.5 5226.914) rotate(180)">
          <line id="Line_5" data-name="Line 5" x1="62" transform="translate(222.5 5202.5)" fill="none" stroke="#47a01a" stroke-linecap="round" stroke-width="2"/>
          <line id="Line_6" data-name="Line 6" x1="22" y2="23" transform="translate(222.5 5179.5)" fill="none" stroke="#47a01a" stroke-linecap="round" stroke-width="2"/>
          <line id="Line_7" data-name="Line 7" x2="22" y2="23" transform="translate(222.5 5202.5)" fill="none" stroke="#47a01a" stroke-linecap="round" stroke-width="2"/>
        </g>
      </svg>
    </div>
    <div class="carousel-nav-next carousel-nav-btn" (click)="navigateItem(true)">
      <svg xmlns="http://www.w3.org/2000/svg" width="64.414" height="48.828" viewBox="0 0 64.414 48.828">
        <g id="Group_14" data-name="Group 14" transform="translate(285.5 5226.914) rotate(180)">
          <line id="Line_5" data-name="Line 5" x1="62" transform="translate(222.5 5202.5)" fill="none" stroke="#47a01a" stroke-linecap="round" stroke-width="2"/>
          <line id="Line_6" data-name="Line 6" x1="22" y2="23" transform="translate(222.5 5179.5)" fill="none" stroke="#47a01a" stroke-linecap="round" stroke-width="2"/>
          <line id="Line_7" data-name="Line 7" x2="22" y2="23" transform="translate(222.5 5202.5)" fill="none" stroke="#47a01a" stroke-linecap="round" stroke-width="2"/>
        </g>
      </svg>
    </div>
  </div>

  <!-- Items -->

  <ul class="items">
    <li *ngFor="let item of items; let idx = index" class="item {{item.class}}" id="item-{{idx}}" [ngClass]="idx == activeitem && theme !== 'hero'  ? 'on': ''">

      <div *ngIf="item.link" class="item-link" (click)="clickItem(item.link)"></div>

      <div class="item-details center table">
        <div class="item-details-wrap center v-align" *ngIf="item.title || item.desc">
          <div class="item-title" *ngIf="item.title" [innerHTML]="item.title"></div>
          <div class="item-desc"  *ngIf="item.desc" [innerHTML]="item.desc"></div>
        </div>

      </div>



      <div class="item-img-wrap" #imgwrap>

        <!-- <img #imge src="{{item.path}}" class="item-img" (window:resize)="onResizeFitChildEl(imge,imgwrap)" [ngClass]="imge.width > imge.height ? 'catalogue-image-width': imge.width < imge.height ? 'catalogue-image-height' : 'catalogue-image-equal'" /> -->
          <img *ngIf="theme !== 'hero'" #imge src="{{item.path}}" class="item-img" (window:resize)="lib.onResizeFitChildEl(imge,imgwrap)" />
          <div *ngIf="theme == 'hero'" class="hero-carousel background {{item.path}}"></div>
      </div>

    </li>
  </ul>

  <!-- Thumbnails -->

  <div class="carousel-thumbnails" *ngIf="items.length > 1 && thumbnails=='default'">
    <ul class="thumbs">
      <li *ngFor="let item of items; let tidx = index" class="thumb-item" id="thumb-item-{{tidx}}" [ngClass]="tidx == activeitem ? 'on': 'off'">
        <div class="item-img-thumb-wrap fill-height2">
          <img src="{{item.path}}" class="item-img-thumb v-align2" (click)="selectItem(tidx)" />
        </div>
      </li>
    </ul>
  </div>


  <div class="carousel-thumbnails" *ngIf="items.length > 1 && thumbnails=='dots'">
    <ul class="thumbs">
      <li *ngFor="let item of items; let tidx = index" class="thumb-item" id="thumb-item-{{tidx}}" [ngClass]="tidx == activeitem ? 'on': ''" (click)="selectItem(tidx)">
      </li>
    </ul>
  </div>



</div>
