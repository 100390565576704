import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {EvalPipe} from '../../pipes/eval.pipe/eval.pipe';
import {KeysPipe} from '../../pipes/keys.pipe/keys.pipe';
import {AliasPipe} from '../../pipes/alias.pipe/alias.pipe';
import {FormatPipe} from '../../pipes/format.pipe/format.pipe';

import {Library} from '../../app/app.library';

@Component({
  selector: 'comp-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss'],
  providers:[Library],
  encapsulation: ViewEncapsulation.None
})
export class CarouselComponent {

  @Input() data: any;
  @Input() slideshow: boolean = false;
  @Input() theme: string = 'default';
  @Input() navigation: boolean = true;
  @Input() thumbnails: string = 'default';
  @Input() classes: string;
  @Input() nopause: boolean = false;

  items: any = [];
  activeitem : number = 0;
  interval: number = 5000;
  paused: boolean = false;
  resized:any;
  @Output() callback = new EventEmitter();

  constructor(public lib: Library){
  }

  init(){
    this.items = JSON.parse(this.data);
    if(this.slideshow) this.initSlideshow();
    let _t = this;
    clearTimeout(this.resized);
    this.resized = setTimeout(function(){
       _t.doResize();
     },1000);
  }

  initSlideshow(){
    setInterval(()=>{
      if(!this.paused) {
        this.navigateItem(true);
      }
    },this.interval);
  }

  ngOnInit() {
  }

  ngAfterContentInit(){
  }

  ngAfterViewInit(){
    this.init();
  }

  navigateItem(dir){
    let previtem = this.activeitem;
    if(dir){ this.activeitem = (this.activeitem+1) == this.items.length ? 0 : (this.activeitem+1); }
    else { this.activeitem = this.activeitem == 0 ? (this.items.length-1) : (this.activeitem-1); }

    if(this.theme == 'hero'){
      this.items[this.activeitem].class = 'on';
      setTimeout(()=>{
        this.items[previtem].class = 'off';
      },0);
    }

    this.doResize();
  }

  selectItem(idx){
    this.activeitem = idx;
    this.doResize();
  }

  clickItem(alias){
    let params = {"alias": alias, "action": "changepage", "event": 0, "get" : false};
    this.callback.emit(params);
  }

  hoverItem(){
   if(!!this.nopause) this.paused = true;
  }

  leaveItem(){
    this.paused = false;
  }

  doResize(){
    let _t = this;
    //resize any box images to fit
    let parent = document.querySelectorAll('.carousel');
     [].forEach.call(parent, function(p) {
        let child = p.querySelectorAll('.carousel .item-img-wrap');
        if(child.length){
          child.forEach(c => {
            let childimg = c.querySelectorAll('.item-img');
            if(childimg.length > 0) _t.lib.onResizeFitChildEl(childimg[0],c,0);
          });
        }
     });
  }


}
