import { Component, ViewChild, Output, ElementRef, ViewEncapsulation,DoCheck, OnChanges, HostListener, HostBinding, OnInit, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Parallax from 'parallax-js';


//Lottie
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

//Specific components
import { ChildTemplateComponent } from '../components/ChildTemplateComp/childtemplate.component';
import { ListComponent } from '../components/ListComp/list.component';
import { SideNavComponent } from '../components/SideNavComp/sidenav.component';
//import { MasonryGridComponent } from '../components/MasonryGridComp/masonrygrid.component';

//Specific directives
import { ParallaxDir } from '../directives/parallax.directive';
import { ProgressCircleDir } from '../directives/progresscircle.directive';


import { Service } from '../app/app.service';
import { Library } from '../app/app.library';

import { CookieService } from 'ngx-cookie-service';



declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Service, Library]
})
export class AppComponent implements OnInit, AfterViewInit {

  options: AnimationOptions = {
    path: './assets/videos/titles/videotitles.json',
    //path: ''
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  @ViewChild(ChildTemplateComponent) childTemplate: ChildTemplateComponent;
  @ViewChild(SideNavComponent) sideNavComp: SideNavComponent;
  @ViewChild(ListComponent) listComp: ListComponent;
  @ViewChild(ProgressCircleDir) progressCircleDir: ProgressCircleDir;
  @ViewChildren(ChildTemplateComponent) childTemplates: QueryList<ChildTemplateComponent>;
  @ViewChildren(ListComponent) lists: QueryList<ListComponent>;
  @ViewChild('mainsection') mainsection: any;
  @ViewChild('hero') hero: any;
  @ViewChild('heroscroll') heroscroll: any;
  @ViewChild('main') main: any;

  scrollpos = 0;
  scrolled = 0;
  scrolledEl:string;
  scrollHeight = 0;
  paralpos:number = 0;
  mouseX:number = 0;
  mouseY:number = 0;
  masonryGrid:any;
  alreadyscrolled:boolean = false;
  sectorsViewed: any = [];
  sectorInterest:string = '';
  profile:any = {};
  profilewindow:any;
  topNavHover:boolean = false;
  showTopNavHover:boolean = false;

  // Setup isScrolling variable
  isScrolling:any;
  isFooterStick:boolean = false;
  isBottom:boolean = false;
  animation:string = '';

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('change');
  // }
  //
  // ngDoCheck() {
  //   // ...
  // }


  //include specific directives here
  @HostBinding('attr.dataParallaxDir') dataParallaxDir  = new ParallaxDir();


  @HostListener('window:scroll', ['$event']) onScrollEvent(e){

    //console.dir(e.target.scrollingElement);

    if(!this.showSearch){
      this.scrollpos = e.target.scrollingElement.scrollTop;
      this.scrollHeight = e.target.scrollingElement.scrollHeight;
      e.target.scrollingElement.style.overflow = 'auto';
      //console.log(this.scrollpos);
    } else{
      e.target.scrollingElement.scrollTop = this.scrollpos
      e.target.scrollingElement.style.overflow = 'hidden';
    }

    this.lib.checkForScrolledIn();
    this.checkForGrid();
    this.lib.checkForScrolled();
    this.isBottom = this.lib.isScrolledToBottom();

  }

  @HostListener("window:mousewheel", ["$event"]) onWindowScroll(e:any) {

    //e.preventDefault();
    //e.stopPropagation();
    //console.dir(e);

    let dir = e.deltaY > 0 ? 'down' : 'up';

    this.isFooterStick = false;

    this.lib.checkForScrolledIn();
    this.checkForGrid();
    this.lib.checkForScrolled();
    this.isBottom = this.lib.isScrolledToBottom();
    //
    // e.preventDefault = true;
    //
    // let scrollcontent = document.querySelectorAll('.scroll-content')[0];
    let maincontent = document.getElementById('main');
    let footercontent = document.getElementById('footer');
    let scrollwindow = document.getElementById('scrollwindow-off');
    let heightoffset = 1000;
    //let scrollHeight = maincontent.clientHeight + footercontent.clientHeight - heightoffset;
    let scrollHeight = scrollwindow.clientHeight;
    this.scrollHeight = scrollHeight;
    //
    // if(scrollcontent.attributes['scroll']){
    //   let speed = 200;
    //   let dir = e.deltaY > 0 ? speed : -speed;
    //   let scrollPos = parseInt(scrollcontent.attributes['scroll'].value)+dir;
    //   scrollPos = scrollPos < 0 ? 0 : scrollPos;
    //   if(scrollPos < scrollHeight){
    //     scrollcontent.setAttribute('scroll',scrollPos.toString());
    //     scrollcontent.setAttribute('style','transform: translate3d(0px,-'+scrollPos+'px,0px)!important');
    //     this.scrollpos = scrollPos;
    //   }
    // }




    // let parallaxobj = document.querySelectorAll('.parallax-object')[0];
    // // let paralpos = parallaxobj.scrollTop;
    // // console.log(paralpos);
    // if(parallaxobj){
    //  let p =  parallaxobj.getBoundingClientRect();
    //  //console.dir(window);
    //  //console.log(this.scrollpos);
    //  if(p.top < window.innerHeight && p.bottom < window.innerHeight && p.top > 0 && p.bottom > 0){
    //    //object is in view
    //    console.log(p.top, p.bottom);
    //    let offset = 0.5;
    //    //let dir = e.deltaY > 0 ? offset : -offset;
    //    this.paralpos = e.deltaY > 0 ? Math.round(this.paralpos + offset) : Math.round(this.paralpos - offset);
    //    parallaxobj.setAttribute('style','transform: translate3d(0px,'+this.paralpos+'%,0px)!important');
    //
    //  }
    //
    // }


    // if(paralpos )
    // let speed = 10;
    // let dir = e.deltaY > 0 ? speed : -speed;
    // paralScroll =
    // parallaxobj.setAttribute('style','transform: translate3d(0px,-'+scrollPos+'%,0px)!important');
  }


  @HostListener("window:mousemove", ["$event"]) onMouseMove(e:any) {

    let mouseX = e.clientX;
    let mouseY = e.clientY;
    this.mouseX = mouseX;
    this.mouseY = mouseY;
    const screenWidth = window.outerWidth;
    const offset = 100;

    // if(mouseX >= screenWidth - offset){
    //       this.sideNavComp.on = true;
    // }else{
    //       this.sideNavComp.on = false;
    // }

   //this.sideNavComp.on = mouseX >= screenWidth - offset ? true : false;
    //if(this.sideNavComp.on) this.sideNavComp.iniSideNav();
  }


  @HostListener("window:CallAngularService", ['$event']) onCallAngularService(e) {
    // Run your service call here
    const action =  e.detail.action;
    const data = e.detail.data;
    switch(action){
      case 'changepage':
       this.changePage(data.alias, 0, [], 0, data.get, false);
      break;
      case 'toggletag':
        //this.childTemplate.filterTag = data;
        this.changeTag(data);
      break;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
   console.log('Back button pressed');
 }

  title = 'Graphic Impressions';
  resized:any;
  setPage = 'home';
  dataLoaded:boolean = false;
  gridLoaded:boolean = false;
  loaded = false;
  imagepath = '../assets/';
  mobile_on = false;
  json:any = {};
  pages = {};
  mainmenu = [];
  footermenu = [];
  headers;
  path:string;
  server:string;
  get:any = false;
  tag:any = false;
  tagfocus:any = false;
  infofocus:any = false;
  router:any;
  showModal = false;
  showSearch:boolean = false;
  showLeftPanel:boolean =  true;
  modal_key:string = '';
  modal_content:any = {};
  t:any;
  fullurl:string = '';
  casestudies:any = [];
  casestudiesFull:any = [];
  articles:any = [];
  windowWidth:number = 0;
    flashOn:boolean = false;

  details = {
    "phone": "+44 (0) 207 253 5444",
    "email": "hello@graphicimpressions.co.uk",
    "address": "<span class='bold'>Graphic Impressions<br>White Collar Factory, 1 Old Street Yard,<br>London EC1Y 8AF</span>"
  }


  constructor(private http:HttpClient, private service:Service, public lib:Library, private loc: PlatformLocation, private meta: Meta, private titleService: Title, private ref:ChangeDetectorRef, private cookieService: CookieService) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.loaded = true;
    this.path = 'json/master'; //has to be stored in assets folder to work
    this.t = this;

    //Get server
    const config = service.getConfig();
    this.server = config.host + config.server + config.version;
    //this.server = 'http://s.chaseandcoadvertising.co.uk:84/chaseportal_server/v1/core/core.php';
    this.getJSON();

    //Handle back button location state
    loc.onPopState(() => {
       let url = window.location.pathname.split( '/' );
       let last = url.length-1;
       let hash = window.location.hash;
       if(url[last].length){ this.changePage(url[last], 0, [], 0, hash, true); }
    });
  }

  //load JSON locally
  getJSON(){
    this.service.loadJSON(this.path).then(r => { this.initJSON(r); });
  }

  //Initiate JSON data into DOM layout
  initJSON(r){
    this.pages = r.pages;
    this.mainmenu = r.menu_main;
    this.footermenu = r.menu_footer;
    this.dataLoaded = true;
    this.casestudies = Object.keys(this.pages).filter(r => this.pages[r].group == 'casestudy-page');
    let _t =this;
    this.casestudiesFull = Object.keys(this.pages).filter(r => this.pages[r].group == 'casestudy-page').map(function(page) {  return _t.pages[page] });
    this.articles = Object.keys(this.pages).filter(r => this.pages[r].group == 'news-page');
  }

  ngOnInit(){
  }

  //Handle ngAfterViewInit processes
  ngAfterViewInit(){
    //Verify cookies and display necessary prompts if none
      if(this.cookieService.check('GI Flash')) this.flashOn = false;
  }

  //Handle once content is loaded
  /*
    This gets information from the url including query parameters or hashtags uses this to load the correct page data
  */
  ngAfterContentInit(){

    //Set default parameters and grab necessary url / page data
    let _t = this;
    let url = window.location.pathname.split( '/' );
    let last = url.length-1;
    let hash = window.location.hash;
    let page = url[last];
    let fullurl = window.location.href;
    this.fullurl =  fullurl;

    //If url contains query, ensure page variable carries this
    if(fullurl.includes('?')) page = page + '?' + fullurl.split('?')[1];

    //Set timeout
    clearTimeout(this.resized);
    this.resized = setTimeout(function(){

      //Load new content for page
      if(page.length){ _t.changePage(page, 0, [], 0, hash); }

      //Handle any images that are not sized correctly once page has loaded
      let parent = document.querySelectorAll('.box');
       [].forEach.call(parent, function(p) {
          let child = p.querySelectorAll('.box-img');
          if(child.length){
             _t.lib.onResizeFitChildEl(child[0],p,0);
          }
       });

   },2000);
  }

  //Change tags
  changeTag(d){
    this.listComp.toggleTagList(d);
    this.ref.detectChanges();
  }

  changePage(alias:any='', mid:number=0, smid:any=[], event:any=0, hash:any=false, back:boolean = false){

      //Hide info text infotextcontainer
      //TODO: More ideal for this to be in the nested list component
      var tt = document.getElementById("infotextcontainer");
      tt.setAttribute('style','opacity:0px');

        //Prep tag variable
        let search = '';
        let tag = false;
        this.tag = false;

        //If hash, append to get variable
        if(typeof hash !== undefined){
          this.get=hash;
        }

        //If on homepage, force play introductary video
        if(alias == 'home'){
          let videoPlayer = <HTMLVideoElement> document.getElementById('vid');
          if(videoPlayer) videoPlayer.play();
        }

        ///Handle get queries
        if(!!alias){
          if(alias.includes('?')){
            let url = alias.split('?');
            alias = url[0];
            search = url[1];
            let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

            let haystack = Object.keys(params);

            //Filter if any tags in query
            let tags = ['sector','tags','service'];
            if (this.lib.containsAny(tags,haystack)){
              this.doTag(params[haystack[0]]);
            }
          }

          //Handle if alias contains hashtag
          if(alias.includes('#')){
            let url = alias.split('#');
            alias = url[0];
            this.get = this.get.length == 0 ? url[1] : this.get;
            hash = url[1];
          }
        }

        //Handle here if undefined
        if(typeof alias == 'undefined'|| !alias){ };

        //If alias is set to 'off' return false - disable page load
        if(alias == 'off'){
          return false;
        } else if(alias == 'modal'){
          //If 'modal', setup content to load modal instead of page
          this.modal_key = hash;
          this.showModal = !this.showModal;
        } else {

        let match = '';

        if(event !== 0){ event.stopPropagation(); }
        this.loaded = false;

          //If no alias, search using mid (Menu Id) value
          if(alias == false){
            let menulink:any = this.mainmenu[mid];
            if(smid.length > 0){
              for(let i = 0, len = smid.length; i < len; i++){
                menulink = menulink["submenu"][smid[i]];
              }
            }
            this.setPage = menulink.alias;
            alias = menulink.alias;
          } else {
            //If alias contains 'http' open new window via link
            if(alias.indexOf('http') !== -1){
              window.open(alias,'_blank');
            } else {

              //Else, loop through menu items and match with alias
              this.mainmenu.forEach(menu => {

                if (menu.alias == alias){

                  match = menu.alias;
                } else if (menu.hasOwnProperty("submenu") && menu["submenu"].length > 0){
                   menu["submenu"].forEach(submenu => {
                     if(submenu.alias == alias){
                       match = submenu.alias;
                     } else if (submenu.hasOwnProperty("submenu") && submenu["submenu"].length > 0){
                       submenu["submenu"].forEach(submenu2 => {
                          if(submenu2.alias == alias){
                            match = submenu2.alias;
                          }
                       });
                     }
                   });
                }
              });
              this.footermenu.forEach(menu => {
                if (menu.alias == alias){
                  match = menu.alias;
                }
              });
              this.setPage = match;
           }
        }

        if(this.mobile_on){
          this.mobile_on = ! this.mobile_on;
        }
        setTimeout(() => {
          this.loaded = true;
        },);

        //Load any animations for the page
        this.loadAnimation(this.setPage);

        //If hash
        if(hash !== false && hash.length > 0) {

          //Set timeout to wait for content
          let _t = this;
          setTimeout(function(){

            //Add hashtag symbol if not
            let  h =  hash.indexOf('#') !== -1 ? hash : '#' + hash;
            //Append hash to alias
            let a = alias + h;
            //Search for hashtag element, removing symbol using substring
            if(<HTMLInputElement>document.getElementById(hash.substring(1))){
              //Scroll to element
              (<HTMLInputElement>document.getElementById(hash.substring(1))).scrollIntoView({block: "nearest"});
              //Set meta title and updated url with hashtag
              if(!back) window.history.pushState({"pageTitle":_t.pages[_t.setPage].title},"", a);
            } else {
              //Handle if no element found
              this.get = hash;
              if(!back) window.history.pushState({"pageTitle":_t.pages[_t.setPage].title},"", a);
            }

          }, 500);
        } else {

          //If no hash, continue with alias
          let a  = alias;

          //If search query, append to alias
          if(search !== '') a = alias + '?' + search;

          //If no back button, set meta title and url
          if(!back) window.history.pushState({"pageTitle":this.pages[this.setPage].title},"", a);

          //Handle if back button
          setTimeout(()=>{
            if(back) window.history.pushState({"page":this.pages[this.setPage].title},this.pages[this.setPage].title, a);
          },1000);

        }

        //Set metadata for page
        this.meta.updateTag({ name: 'description', content: this.pages[this.setPage].meta.description });
        this.meta.updateTag({ name: 'keywords', content: this.pages[this.setPage].meta.keywords });
        this.meta.updateTag({ property : 'og:title', content: this.pages[this.setPage].title });
        this.meta.updateTag({ property : 'og:description', content: this.pages[this.setPage].meta.description });
        this.meta.updateTag({ property : 'og:url', content: this.fullurl });
        this.meta.updateTag({ property : 'twitter:card', content: "summary_large_image" });
        this.meta.updateTag({ property : 'twitter:creator', content: "@wearegraphici" });
        this.meta.updateTag({ property : 'twitter:domain', content: "www.graphicimpresions.co.uk" });

        //Set meta image using background of pages' hero, if any
        setTimeout(()=>{
          if(this.hero){
          let imageurl = getComputedStyle(this.hero.nativeElement).backgroundImage.slice(4, -1).replace(/"/g, "");
          this.meta.updateTag({ property : 'og:image', content: imageurl });
          }
        },);

        //Set title
        this.titleService.setTitle( this.pages[this.setPage].title  + ' | ' + this.title );

        //Scroll to top
        setTimeout(function(){
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        }, 100);

        //Handle sectors sectorsViewed
        this.pages[this.setPage].fields.forEach(field => {
          if(field.name == 'sector') this.sectorsViewed.push(field.value);
        });

        //If user clicks back and browser is IE, trigger refresh so page loads correctly
        if(back && (navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1)) location.reload();

        //retrigger any grid if anything
        //this.doGrid(this.tag);

  }

  //Temporary fix for svgs
  //this.lib.fixSvg();

  //Send data to GA
  // gtag('config', this.GA_trackingcode, {
  //     'page_title' : this.pages[this.setPage].title,
  //     'page_path': this.pages[this.setPage].alias
  // });

  }

  //Toggle search component
  toggleSearch(){
    this.showSearch = !this.showSearch;
    document.documentElement.style.overflow = this.showSearch ? 'hidden' : 'auto';
  }

  //Toggle main menu
  toggleMobileMenu(wash = false){
    if(wash){
      if(this.mobile_on){
        this.mobile_on = ! this.mobile_on;
      }
    }else{
        this.mobile_on = ! this.mobile_on;
    }
  }

  //Close window
  closeWindow(obj){
    this.showLeftPanel = false;
  }

  //Handle callbacks
  async handleCallback(params){
    switch(params.action){
      case "changepage":
      this.changePage(params.alias,0,[],params.event, params.get);
      break;
      case "showmodal":
        this.modal_key = params.get;
        this.showModal = !this.showModal;
      break;
      case "scrollto":
        this.lib.scrollTo(params.alias,params.event,this.scrollpos);
      break;
      case "filtergrid":
        await this.doTag(params.get);
        await this.filterGrid();
      break;
    }
  }

  //Setup grids if any loaded
  doGrid(tag:any = false){
    let foundgrid = false;
    setTimeout(()=>{
      this.childTemplates.forEach(async child => {
        if(child.grids.length){
          foundgrid = true;
          child.grids.forEach((grid) => this.masonryGrid = grid);
        }
      });
      if(foundgrid) this.filterGrid();
    },);
  }

  //Filter grid
  async filterGrid(){
    await this.masonryGrid.filterTags();
    setTimeout(()=>{
      this.lib.checkForScrolledIn(this.alreadyscrolled);
      this.alreadyscrolled = false;
    },1000);
  }

  //Check for grids
  checkForGrid(){
    let els = document.querySelectorAll('.is-grid');
    els.forEach(el =>{
      let box = el.getBoundingClientRect();
      let isscrolled = box.top < window.innerHeight && box.bottom >= 0;
      if(isscrolled){
        if(!el.classList.contains('grid-loaded')){
          el.classList.add('grid-loaded');
          this.doGrid(this.tag);
        }
      }
    });
  }

  //Configure tags
  async doTag(v){
    if(!!v){
      if(v.includes('&')){
         v = v.replace(' & ', '-');
      } else if(v.includes(' ')) {
        console.log('string has space');
         v = v.replace(/\s+/g, '-');
      }
    }
    this.tag = v;
  }


writeAttributes(attributes){
  return attributes;
}

//Handle controls
hideControls(e){
  console.log('hiding controls');
  e.controls=false;
}

showControls(e){
  setTimeout(function(){ e.controls=true; },1000);
}

//Scroll to main element
scrollToMain(){
  if(this.setPage == 'home') { this.changePage('our-work'); }
  window.scrollTo({ left: 0, top: 825, behavior: 'smooth' });
}

//Handle specific animations
loadAnimation(alias){
  this.animation = '';

  //Determine animation by page alias
  switch(alias){
    case '':
      this.animation = 'laptop';
    break;
    case 'home':
      this.animation = 'laptop';
    break;
    case 'about-us':
      this.animation = 'aboutus';
    break;
    case 'contact':
      this.animation = 'maps';
    break;
    case 'blog':
      this.animation = 'sofa';
    break;
    case 'our-work':
      this.animation = 'computer';
    break;
  }

  if(alias.indexOf('casestudy') !== -1) this.animation = 'computer';

  //If there is an animation for page, create element
  if(this.animation !== ''){
    setTimeout(()=>{
      let animframe = <HTMLIFrameElement>document.querySelectorAll('.animationframe')[0];
      animframe.src = './assets/animations/'+this.animation+'/'+this.animation+'.html';
      animframe.classList.remove('hide');
      // Hide/reveal animation
      if(this.animation !== ''){
        animframe.classList.remove('hide');
      } else{
        animframe.classList.add('hide');
      }
    },300);

  }
}

//Cookie processes
async acceptFlash(){
  let expiredDate = new Date();
  expiredDate.setDate( expiredDate.getDate() + 365 );
  //await this.cookieService.set( 'Norse Cookies', 'Accept cookies', expiredDate,'','https://www.daventrynorse.co.uk',true);
  await this.cookieService.set( ' GI Flash', 'Close flash');
  this.flashOn = false;
}


}
