import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {Library} from '../../app/app.library';

@Component({
  selector: 'comp-socialshare',
  templateUrl: 'socialshare.component.html',
  styleUrls: ['socialshare.component.scss'],
  providers:[Library]
})
export class SocialShareComponent {

  //This is a simple pop-up component which displays social media icons within a div

  shareon:boolean = false;
  icon1:string;
  icon2:string;
  icon3:string;
  icon4:string;
  icon5:string;
  fullurl:string = '';

  constructor(public lib: Library) {
    this.fullurl = window.location.href;
  }

  //Toggle functions

  toggleShareOn(){
    this.shareon = true;
  }

  toggleShareOff(){
    this.shareon = false;
  }

}
