<div class="socialshare-container" (mouseout)="toggleShareOff()" (mouseover)="toggleShareOn()" [ngClass]="shareon ? 'on' : ''">
  <div class="socialshare-list">
    <div class="social-icons-wrap">
      <!-- <img [src]="icon1 ? 'assets/icons/'+icon1 : 'assets/icons/icon_instagram_circle_darkgrey.svg'" (mouseover)="icon1 = 'icon_instagram_circle_white.svg'" (mouseout)="icon1 = 'icon_instagram_circle_darkgrey.svg'" class="social-icon" /> -->
      <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{fullurl}}"><img [src]="icon2 ? 'assets/icons/'+icon2 : 'assets/icons/icon_facebook_circle_darkgrey.svg'" (mouseover)="icon2 = 'icon_facebook_circle_white.svg'"  (mouseout)="icon2 = 'icon_facebook_circle_darkgrey.svg'"  class="social-icon" /></a>
      <a target="_blank" href="http://twitter.com/share?url={{fullurl}}"><img [src]="icon3 ? 'assets/icons/'+icon3 : 'assets/icons/icon_twitter_circle_darkgrey.svg'" (mouseover)="icon3 = 'icon_twitter_circle_white.svg'" (mouseout)="icon3 = 'icon_twitter_circle_darkgrey.svg'" class="social-icon" /></a>
      <a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url={{fullurl}}"><img [src]="icon4 ? 'assets/icons/'+icon4 : 'assets/icons/icon_linkedin_circle_darkgrey.svg'" (mouseover)="icon4 = 'icon_linkedin_circle_white.svg'"  (mouseout)="icon4 = 'icon_linkedin_circle_darkgrey.svg'"  class="social-icon" /></a>
      <a href="mailto:?body={{fullurl}}"><img [src]="icon5 ? 'assets/icons/'+icon5 : 'assets/icons/icon_email_circle_darkgrey.svg'" (mouseover)="icon5 = 'icon_email_circle_white.svg'"  (mouseout)="icon5 = 'icon_email_circle_darkgrey.svg'"  class="social-icon" /></a>
    </div>
  </div>
  <img src="assets/icons/icon_share_primary.svg" (mouseover)="toggleShareOn()" class="social-icon share-icon" />
</div>
