import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', children: [] },
  { path: 'home', children: [] },
  { path: 'our-work', children: [] },
  { path: 'about-us', children: [] },
  { path: 'contact', children: [] },
  { path: 'blog', children: [] },
  { path: 'landingpage-hotels', children:[] },
  { path: 'casestudy-jupiter-christmas', children: []}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
