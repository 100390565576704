import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Input() modal_content: any;
  @Input() modal_key: string;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() callback = new EventEmitter();

  modalTitle:string;
  modalContent:string;
  modal_idx:number = 0;
  cta:boolean = false;
  cta_action:string = '';
  tmp_close:boolean = false;
  show_nav:boolean = false;

  constructor() {}

  ngOnInit() {}

  //Update on any events made to modal
  ngOnChanges() {
    if(this.visible){
      this.tmp_close = false;
      this.modal_idx = Object.keys(this.modal_content).indexOf(this.modal_key);
      this.update();
    }
  }

  //Update specific modal properties
  update(){
    this.modalTitle = this.modal_content[Object.keys(this.modal_content)[this.modal_idx]].title;
    this.modalContent = this.modal_content[Object.keys(this.modal_content)[this.modal_idx]].content;
    this.cta = this.modal_content[Object.keys(this.modal_content)[this.modal_idx]].cta;
    this.cta_action = this.modal_content[Object.keys(this.modal_content)[this.modal_idx]].cta_action ? this.modal_content[Object.keys(this.modal_content)[this.modal_idx]].cta_action : '' ;
    this.show_nav = this.modal_content[Object.keys(this.modal_content)[this.modal_idx]].show_nav ? true: false;
  }

  //Close modal
  close() {
    this.tmp_close = true;
    setTimeout(()=>{
      this.visible = false;
      this.visibleChange.emit(this.visible);
    },500);
  }

  //Navigation
  next(){
    if(this.modal_idx == (Object.keys(this.modal_content).length-1)){
      this.modal_idx = 0;
    } else{
      this.modal_idx++;
    }
    this.update();
  }

  prev(){
    if(this.modal_idx == 0){
      this.modal_idx = (Object.keys(this.modal_content).length-1);
    }else{
      this.modal_idx--;
    }
    this.update();
  }

  //Handle specific CTA actions
 doCTA(){
   this.close();
   let cta:any = this.cta;
   let cta_action:string = this.cta_action;
   let params:any = {};
   switch(cta){
     case true:
       params = {"alias": "contact-us", "action": "changepage", "event": 0, "get": {"service":this.modalTitle}};
       this.callback.emit(params);
     break;
     case 'Confirm':
       this.close();
     break;
     case 'Visit website':
       params = {"alias": cta_action, "action": "changepage", "event": 0, "get": false};
       this.callback.emit(params);
     break;
   }


  }
}
