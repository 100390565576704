import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';


import { Observable, of, Subject  } from 'rxjs';
import { map } from 'rxjs/operators';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/map';

import {EvalPipe} from '../../pipes/eval.pipe/eval.pipe';
import {KeysPipe} from '../../pipes/keys.pipe/keys.pipe';
import {AliasPipe} from '../../pipes/alias.pipe/alias.pipe';
import {FormatPipe} from '../../pipes/format.pipe/format.pipe';

import {Library} from '../../app/app.library';

@Component({
  selector: 'news-template',
  templateUrl: 'newstemplate.component.html',
  styleUrls: ['newstemplate.component.scss'],
  providers:[Library],
  encapsulation: ViewEncapsulation.None
})
export class NewsTemplateComponent {

  @Input() content: any;
  @Input() get: any;
  @Input() t: any;
  @Input() article: string = '';
  @Output() callback = new EventEmitter();

  constructor(public lib: Library, private http: HttpClient){}

  ngAfterContentInit(){}

  emit(params){
    this.callback.emit(params);
  }

  //Navigation

  navPage(dir){

    let page = this.t.setPage;
    let articles = this.t.articles;
    let cid = articles.indexOf(page);

    switch(dir){
      case 'prev':
        cid = cid == 0 ? articles.length-1 : cid-1;
      break;
      case 'next':
         cid =  cid == articles.length-1 ? 0 : cid+1;
      break;
    }

    let article = articles[cid];
    this.t.changePage(article);
  }

}
