<div *ngIf="el.type =='feed'" class="{{el.classes}}">
  <div *ngIf="el.datatype == 'group'">

    <!-- filters -->
    <div class="row full-width">
      <div class="cols feed-filter-col">

        <!-- Sort module -->
        <div *ngIf="el.show_sort" class="sortbox filterbox">
          <h4>Sort</h4>
          <select [(ngModel)]="sort" (ngModelChange)="sortFeed()">
         <option [ngValue]="opt" *ngFor="let opt of sort_options">{{opt.value}}</option>
       </select>
        </div>

        <!-- Search box -->
        <div *ngIf="el.show_search" class="searchbox filterbox">
          <h4>Search</h4>
          <div class="wrap">
            <input type="text" [(ngModel)]="query" (ngModelChange)="queryFeed()" />
          </div>
        </div>


        <!-- Tag module -->
        <div *ngIf="el.show_filter" class="tagbox filterbox">
          <h4>Tags</h4>
          <div *ngFor="let tag of tags | keys" class="tag">
            <div *ngIf="lib.objLength(tag.value.subtags) == 0" class="maintag">
              <input type="checkbox" name="{{ tag.key | format : 'string' }}" checked="{{ filters &amp;&amp; -1 !== filters.indexOf(tag.key) ? 'checked' : '' }}" (change)="filterSelection($event)" />
              <span class="maintag bold">{{tag.key | format : 'string'}}</span> ({{tag.value.count}})
            </div>
            <div *ngIf="lib.objLength(tag.value.subtags) > 0" class="subtags">
              <input type="checkbox" name="{{ tag.key | format : 'string' }}" checked="{{ filters &amp;&amp; -1 !== filters.indexOf(tag.key) ? 'checked' : '' }}" (change)="filterSelection($event)" />
              <span class="maintag bold">{{tag.key | format : 'string'}}</span> ({{tag.value.count}})
              <div *ngFor="let subtag of tag.value.subtags | keys" class="subtag">
                <input type="checkbox" name="{{ subtag.key | format : 'string' }}" checked="{{ filters &amp;&amp; -1 !== filters.indexOf(subtag.key) ? 'checked' : '' }}" (change)="filterSelection($event)" /> {{subtag.key}} ({{subtag.value.count}})
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- main list -->
      <div class="cols feed-list-col">

        <!-- paginated feed -->
        <div *ngIf="!feedDisplay">
        </div>
        <div *ngIf="feedDisplay">
          <div *ngFor="let page of pagination[pagindex]; let pidx = index" class="feed-row row-{{rid}}" [ngClass]="pidx == 0 &amp;&amp; rid == 0 ? 'first full-width': 'col col-2'">
            <div class="feed-item {{pages[page.key].tags | alias}}">
              <div class="item-wrapper">
                <div class="row full-width">

                  <div class="cols col1" [ngClass]="pidx == 0 &amp;&amp; rid == 0  &amp;&amp; setPage == 'news'  ? 'col-2': ''">
                   <div *ngFor="let field of el.fields" class="field-wrapper">
                      <div *ngIf="field.type == 'static'">

                        <h3 *ngIf="field.name == 'title'" (click)="doCallback('changePage',[page.key,0,[],$event,false])" [innerHTML]="field | eval : 'key': pages[page.key]" class="{{field.classes}}"></h3>

                        <div *ngIf="field.name !== 'title'" [innerHTML]="field | eval : 'key': pages[page.key]" class="{{field.classes}}"></div>

                      </div>
                    </div>

                    <div *ngFor="let field of pages[page.key].fields" class="field-wrapper">
                      <div *ngIf="field.type == 'currency'">
                        <p class="{{field.classes}}">
                          {{field.key}} : {{field.prefix}} {{field.value | currency:'GBP':true}} {{field.suffix}}
                        </p>
                      </div>
                    </div>

                    <div class="feed-tag">News > {{el.tag_filter}}</div>

                  </div>


                  <div class="cols col-2 col2" *ngIf="pidx == 0 &amp;&amp; rid == 0">

                    <div *ngFor="let field of el.fields">
                      <div *ngIf="field.type == 'image'" class="feed-img-section">
                        <div class="feed-img-container">
                        <div class="feed-img-wrap" #div>
                          <img src="{{ field | eval : 'key': pages[page.key]}}" width="100px" class="{{field.classes}} feed-img" #imge (window:resize)="lib.onResizeFitChildEl(imge,div)" />
                        </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- pagination navigation -->

          <div *ngIf="pagination.length > 1" class="pagination_wrapper row">
            <div class="pad-horiz-20">

              <div class="col pag-controls pag-control-start">
                <div class="wrap float-left">
                  <button (click)="setPagIndex(0)">First</button>
                  <button (click)="navPag(0)">Prev</button>
                </div>
              </div>
              <div class="col pag-list">
                <ul>
                  <li *ngFor="let pag of pagination;let pid = index" (click)="setPagIndex(pid)">
                    <span [ngClass]="pagindex == pid ? 'active': ''">{{pid+1}}</span>
                  </li>
                </ul>
              </div>
              <div class="col pag-controls pag-control-end">
                <div class="wrap float-right">
                  <button (click)="navPag(1)">Next</button>
                  <button (click)="setPagIndex(pagination.length-1)">Last</button>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</div>
