
<div [ngSwitch]="el.tag" class="element-wrapper">

       <!-- Various HTML Text elements -->
       <h1 *ngSwitchCase="'h1'" [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></h1>
       <h2 *ngSwitchCase="'h2'" [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></h2>
       <h3 *ngSwitchCase="'h3'" [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></h3>
       <h4 *ngSwitchCase="'h4'" [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></h4>
       <h5 *ngSwitchCase="'h5'" [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></h5>
       <h6 *ngSwitchCase="'h6'" [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></h6>
       <p *ngSwitchCase="'p'"   [innerHTML]="el.value | safe : 'html'" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></p>
       <p *ngSwitchCase="'p-eval'"   [innerHTML]="el.value | format: 'eval' : deepget" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></p>
       <a *ngSwitchCase="'a'"   [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}" href="{{el.href}}"></a>
       <span *ngSwitchCase="'span'" [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></span>

       <!-- Iframes -->
       <div *ngSwitchCase="'iframe'">
        <iframe *ngIf="!el.link"  [src]="el.value | safe : 'url'" width="100%" height="100%" frameborder="0" allowfullscreen class="{{el.classes}}" ></iframe>
        <iframe *ngIf="el.link" [src]="el.value | safe : 'url'" class="{{el.classes}}" (click)="parent.changePage(el.link,0,[],$event, el.get)" width="230" height="230" ></iframe>
       </div>

       <!-- Miscellaneous div elements -->
       <div *ngSwitchCase="'div'">

            <!-- Feed component -->
           <comp-feed [el]="el" [eid]="eid" [id]="idx" [rid]="rid" [pages]="pages" (callback)="handleCallback($event)"></comp-feed>

            <!-- Spacing -->
            <div *ngIf="el.type == 'empty' || el.type == 'space'"  [innerHTML]="el.value" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></div>

            <!-- Content container -->
            <div *ngIf="el.type == 'copy'"  [innerHTML]="lib.isObject(el.value) ? el.value.value : el.value | safe : 'html'" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}"></div>

            <!-- CTA Buttons -->
            <div *ngIf="el.type == 'cta'" class="btn-wrap">
              <div class="{{el.classes}}">
                <div *ngIf="el.href == ''" class="cta-wrap" (click)="parent.changePage(el.link,0,[],$event, el.get)">
                  {{el.value}}
                </div>
                <div *ngIf="el.href !== ''" class="cta-wrap">
                  <a style="cursor:pointer" (click)="parent.changePage(el.href,0,[],$event, el.get)">{{el.value}}&nbsp;<span></span></a>
                </div>
              </div>
            </div>

            <!--Handle Sections which contain nested child components -->
            <div *ngIf="el.type == 'section' &amp;&amp; el.format == 'cols'" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}">
                 <div *ngFor="let sec of el.value; let cidx = index" class="cols col-{{el.value.length}} col{{cidx+1}} {{sec.outerclasses}}">
                   <div class="{{el.innerclasses}} {{sec.innerclasses}}">
                     <child-template [el]="sec" [eid]="eid" [idx]="idx" [get]="get" (callback)="emit($event)"></child-template>
                   </div>
                </div>
            </div>

            <div *ngIf="el.type == 'section' &amp;&amp; el.format == 'rows'" id="el-row{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}">
              <div *ngIf="el.link.length" (click)="parent.changePage(el.link,0,[],$event, false)" class="cover pointer"></div>
                 <div *ngFor="let sec of el.value" class="row-{{el.value.length}}">
                   <div>
                     <child-template [el]="sec" [eid]="eid" [idx]="idx" [get]="get" (callback)="emit($event)"></child-template>
                   </div>
                </div>
            </div>
      </div>

      <!-- images -->
       <div *ngSwitchCase="'img'" [ngClass]="el.link &amp;&amp; el.link != '' ? 'pointer': ''" (click)="parent.changePage(el.link,0,[],$event, el.get)" [ngStyle]="{height: el.height, width: el.width}" #div (window:resize)="lib.onResizeEl(div)" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="el-img {{el.classes}} rel image-wrap {{el.wrap}}">
          <div *ngIf="el.overlay != ''" class="overlay img-overlay">
              <div *ngIf="el.watermark != ''" class="watermark">
                <img *ngIf="el.watermark.tag == 'img'" src="{{el.watermark.value}}" class="{{el.watermark.classes}}" />
                <h3  *ngIf="el.watermark.tag == 'h3'" class="{{el.watermark.classes}}">{{el.watermark.value}}</h3>
                <i  *ngIf="el.watermark.tag == 'i'" class="{{el.watermark.classes}}"></i>
                <div  *ngIf="el.watermark.tag == 'icon'" class="{{el.watermark.classes}}">
                  <img src="/assets/{{el.watermark.value}}"  class="icon" />
                </div>
                <div *ngIf="el.watermark.tag == 'div'" class="{{el.watermark.classes}}" [innerHTML]="el.watermark.value"></div>
              </div>
              <div class="overlay background-{{el.overlay}} opacity-05"></div>
          </div>
         <img *ngIf="!el.src.includes('html')" src="{{el.src}}" [ngStyle]="{'width': el.width, 'height': el.height}" alt="{{el.alt}}" class="{{el.imgclasses}}" />
         <iframe *ngIf="el.src.includes('html')" [src]="el.src | safe : 'url'" class="{{el.imgclasses}}" [ngStyle]="{'width': el.width, 'height': el.height}" ></iframe>

       </div>


      <!-- Box element - A square container with option of a background image and overlayed with text elements -->
      <div *ngSwitchCase="'box'" (click)="parent.changePage(el.link,0,[],$event, el.get)" class="box-wrap el-box rel" [ngClass]="el.link &amp;&amp; el.link != '' ? 'active': ''" [ngStyle]="{height: el.height}" #div id="el-col{{idx}}-{{el.tag}}-{{eid}}">
         <div class="box {{el.classes}}">
             <div *ngIf="el.overlay != ''" class="overlay box-overlay">
                 <div *ngIf="el.watermark.value != ''" class="watermark">
                   <img *ngIf="el.watermark.tag == 'img'" src="{{el.watermark.value}}" class="{{el.watermark.classes}}" />
                   <div *ngIf="el.watermark.tag == 'div'" class="{{el.watermark.classes}}" [innerHTML]="el.watermark.value"></div>
                   <h3  *ngIf="el.watermark.tag == 'h3'" class="{{el.watermark.classes}}" [innerHTML]="el.watermark.value"></h3>
                   <h4  *ngIf="el.watermark.tag == 'h4'" class="{{el.watermark.classes}}" [innerHTML]="el.watermark.value"></h4>
                   <p *ngIf="el.watermark.tag == 'p'" class="{{el.watermark.classes}}" [innerHTML]="el.watermark.value"></p>
                   <i  *ngIf="el.watermark.tag == 'i'" class="{{el.watermark.classes}}"></i>
                   <div  *ngIf="el.watermark.tag == 'icon'" class="box-icon {{el.watermark.classes}}">
                     <img src="/assets/{{el.watermark.value}}"  class="icon" />
                   </div>
                 </div>
                 <div class="overlay background-{{el.overlay}} overlay-wash" [ngClass]="el.overlayclass &amp;&amp; el.overlayclass !== '' ? el.overlayclass : 'opacity-0'"></div>
             </div>
            <img *ngIf="el.type == 'image'" src="{{el.src}}" alt="{{el.alt}}" class="box-img {{el.imgclasses}}" #imge (window:resize)="lib.onResizeFitChildEl(imge,div)" />
            <iframe *ngIf="el.type == 'iframe'" [src]="el.value | safe : 'url'" class="{{el.imgclasses}}" width="230" height="230" ></iframe>
         </div>
       </div>

       <!-- News box element - A square container layout used for the news page -->
       <div *ngSwitchCase="'newsbox'" class="el-box box-wrapper newsbox {{el.classes}} rel" [ngStyle]="{height: el.height}" #div (window:resize)="lib.onResizeEl(div)" id="el-col{{idx}}-{{el.tag}}-{{eid}}">
          <div class="img-wrap">
            <img src="{{el.value}}" [ngStyle]="{'width': el.width, 'height': el.height, 'top': el.top, 'left': el.left}" alt="{{el.alt}}" />
          </div>
          <div *ngIf="el.overlay != ''" class="overlay box-overlay">
              <div *ngIf="el.detail != ''" class="detail">
                <a *ngIf="el.detail.tag == 'a'" href="{{el.detail.href}}" class="btn icon-arrow-next">{{el.detail.value}}</a>
                <div class="date">
                  {{el.detail.date}}
                </div>
              </div>

          </div>
      </div>

      <!--List Component -->
      <div *ngSwitchCase="'ul'" >
        <comp-list [el]="el" [eid]="eid" [id]="idx"  [parent]="parent" [g]="get"></comp-list>
      </div>

       <ol *ngSwitchCase="'ol'" id="el-col{{idx}}-{{el.tag}}-{{eid}}" class="{{el.classes}}">
         <li *ngFor="let li of el.value | eval : 'false'"><span [innerHTML]="li.value"></span></li>
       </ol>

       <!--masonry grid list-->
       <div *ngIf="el.classes.includes('masonry-list-two')">
          <comp-masonrygrid #masonryGridComp *ngIf="el.classes.includes('masonry-list-two')" [parent]="parent" [el]="el" [eid]="eid" [id]="idx" [rid]="rid" [pages]="pages" [attrDir]="el.attributes" [comp]="masonryGridComp" [noDir]="true" (callback)="handleCallback($event)" class="is-grid"></comp-masonrygrid>
       </div>

       <!-- Carousel component - New method of calling a specific component -->
      <div *ngSwitchCase="'component'" class="{{el.classes}}">
        <div [ngSwitch]="el.type">
          <div *ngSwitchCase="'carousel'">
            <comp-carousel #comp [data]="el.value" [thumbnails]="'off'" [theme]="el.elclasses" [slideshow]="true" [classes]="el.classes" [attrDir]="el.attributes" [comp]="comp" [noDir]="true"></comp-carousel>
          </div>
        </div>
      </div>

      <!-- Form component -->
      <div *ngSwitchCase="'form'" class="{{el.classes}}" #f>
        <comp-contact [el]="el"></comp-contact>
      </div>

  </div>
