import { Component, OnInit, Input, ElementRef, Output, OnChanges, EventEmitter, SimpleChanges, HostBinding, Directive, Injectable, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';

//https://css-tricks.com/building-progress-ring-quickly/

@Directive({
  selector: '[progressCircle]'
})
export class ProgressCircleDir implements OnChanges {

  @Input() value: any;
  @Input() mouseX: number;
  @Input() mouseY: number;

  circle:any;
  radius:number;
  circumference:number;

  constructor() {
    setTimeout(()=>{
        //console.log('progress circle directive');
        this.circle = document.querySelector('circle');
        this.radius = this.circle.r.baseVal.value;
        this.circumference = this.radius * 2 * Math.PI;
        this.circle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
        this.circle.style.strokeDashoffset = `${this.circumference}`;
        this.setProgress(0);
    },);
   }

   ngOnChanges(changes: SimpleChanges){
      if(changes.value){
        if (this.value < 101 && this.value > -1) {
          this.setProgress(this.value);
        }
      }
      if(changes.mouseX || changes.mouseY){
        //console.log(this.mouseX);
        //console.log(this.mouseY);
        //this.followMouse(this.mouseX, this.mouseY);
      }
    }

   ngOnInit(){

    }

    setProgress(percent) {
      //console.log(percent);
      const offset = this.circumference - percent / 100 * this.circumference;
      this.circle.style.strokeDashoffset = offset;
    }

    followMouse(x,y){
      let pc =  document.getElementById('progressCircle');
      let offset = 13;
      let nx = x - offset;
      let ny = y - offset;
      pc.style.left = nx.toString();
      pc.style.top = ny.toString();
    }

}
