import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(val, args) {
    let res:any;
    switch(args){
      case 'url':
        res = this.sanitizer.bypassSecurityTrustResourceUrl(val);
      break;
      case 'html':
        res = this.sanitizer.bypassSecurityTrustHtml(val);
      break;
      case 'escape':
        res = this.sanitizer.sanitize(SecurityContext.HTML, this.escapeHtml(val));
      break;
    }
    return res;
  }

  escapeHtml(unsafe) {
    return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
                 .replace(/"/g, "&quot;").replace(/'/g, "&#039;");
  }
}
