import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import {Library} from '../../app/app.library';


@Component({
  selector: 'comp-feed',
  templateUrl: 'feed.component.html',
  styleUrls: ['feed.component.scss'],
  providers:[Library]
})
export class FeedComponent {

  @Input() pages: any;
  @Input() setPage: string;
  @Input() el: any;
  @Input() eid: number;
  @Input() idx: number;
  @Input() rid: any;
  @Output() callback = new EventEmitter();

  resized:any;
  pagindex:number = 0;
  pagination:any = [];
  filteredpages:any = [];
  sortedpages:any = [];
  maxresults:number = 5;
  feedDisplay:boolean =  false;
  filters: any = [];
  query: string = '';
  tags: any = {};
  tags_ex: string = 'partnership group';
  sort: any = {};
  sort_options: any = [
    {"key":"price-asc","value":"Price - Ascending","type":"number", "order":"asc"},
    {"key":"price-des","value":"Price - Descending","type": "number", "order": "desc"},
    {"key":"name-asc","value":"Name - Ascending","type": "string", "order": "asc"},
    {"key":"name-des","value":"Name - Descending", "type": "string", "order": "desc"}]

  constructor(public lib: Library){}


    ngAfterContentInit(){
      //move to feed component;
      if(this.el.type == 'feed'){
        this.createTags();
        this.getFilteredPages();
      }
    }

    /* Callback */

    doCallback(a,p){
      //console.log(a);
      //console.log(p);
      this.callback.emit({'a':a,'p':p});
    }

    /* Feed functions  */

    getFilteredPages(){
      this.filteredpages = []; this.pagination = [];let i =0; this.pagindex = 0; this.feedDisplay = false;
      let feedpages = Object.keys(this.pages).filter(p => this.pages[p].group == this.el.dataset.trim() && this.pages[p].tags.includes(this.el.tag_filter)).map(p => { return p; });
      feedpages.forEach(page =>{
          let tags = this.pages[page].tags ? this.pages[page].tags : ''; let title = this.pages[page].title; let idx = i;
          //console.log(this.pages);
          if(this.filterCheck(tags,title,idx)) this.filteredpages.push({'key': page, 'value': this.pages[page]});
          i++;
          if(i == feedpages.length) {
            this.filteredpages.reverse();
            this.doPagination(); }
      });
    }

    setPagIndex(idx){
      this.pagindex = idx;
    }

    navPag(dir){
      let d = this.pagination; var sid = this.pagindex;
      dir ? sid < (d.length-1) ? this.pagindex++ : sid : sid > 0 ? this.pagindex-- : sid;
    }

    queryFeed(){
      this.getFilteredPages();
    }

    /* Sort */

    sortFeed(){
      switch(this.sort.key){
        case 'price-asc':
            this.filteredpages.sort(function(a, b) {
              a = a.value.fields.filter(f => f.type == 'currency');
              b = b.value.fields.filter(f => f.type == 'currency');
              return parseFloat(a[0].value) - parseFloat(b[0].value);
            });
        break;
        case 'price-des':
            this.filteredpages.sort(function(a, b) {
              a = a.value.fields.filter(f => f.type == 'currency');
              b = b.value.fields.filter(f => f.type == 'currency');
              return parseFloat(b[0].value) - parseFloat(a[0].value);
            });
        break;
        case 'name-asc':
            this.filteredpages.sort(function(a, b) {
              if(a.key < b.key) return -1;
              if(a.key > b.key) return 1;
              return 0;
            });
        break;
        case 'name-des':
            this.filteredpages.sort(function(a, b) {
              if(b.key < a.key) return -1;
              if(b.key > a.key) return 1;
              return 0;
            });
        break;
      }
      this.sortedpages = this.lib.deepCopy(this.filteredpages);
      this.doPagination(this.sortedpages);
    }

    /* Pagination */

    doPagination(l = this.filteredpages){
      let i = 0;let section = []; let p = [];
      setTimeout(()=>{
        l.forEach(page=>{
         if(page.value.group == this.el.dataset.trim()){
           section.push(page);
            if(l.length <= this.maxresults){
               p[0]=section;
            }else{
              if(i == (l.length - 1)){
                    p.push(section);
              } else {
                if(i % this.maxresults == 0 && i !== 0){
                    p.push(section);
                    section = [];
                }
              }
            }
            i++;
          }
        });
        this.pagination = this.lib.deepCopy(p);
        this.feedDisplay = true;
      },);
    }

    filterSelection(event){
      let e = event.target.name.trim();
      if (event.target.checked) {
            if (this.filters.indexOf(e) < 0) {
                  this.filters.push(e);
            }
       } else {
             if (this.filters.indexOf(e) > -1)
              {
                  this.filters.splice(this.filters.indexOf(e), 1);
              }
      }
      this.getFilteredPages();
    }

    /* Filter display */

    filterCheck(tags,title,idx){
     let tgs = tags.split(',');
     let tagcheck:any = [];
     tgs.forEach(t => { tagcheck.push(this.filters.indexOf(t.trim()) === -1 ? -1 : 0); });
     let check1 = new RegExp("\\b" + this.query.toLowerCase() + "\\b").test(tags.toLowerCase());
     let check2 = new RegExp("\\b" + this.query.toLowerCase() + "\\b").test(title.toLowerCase());
     let r = (tagcheck.indexOf(0) > -1 && ((check1) || (check2))) || (this.filters.length == 0 && this.query == '') || (this.filters.length == 0 && (check1 || check2));
     return r;
    }

    /* Setup tags for selection and counts */

    createTags(){
      Object.keys(this.pages).forEach(page => {
        if(this.pages[page].group == this.el.dataset.trim()){
          //console.log(this.pages[page]);
          if(this.pages[page].tags.indexOf(',')){
            let tags = this.pages[page].tags.split(',');
            tags.forEach(tag => {
              if(!this.tags_ex.includes(tag)){
                if(!this.lib.isProperty(this.tags,tag)){
                    //this.tags[maintag].subtags = { "subtag":subtag, "count": 1};
                    this.tags[tag] = { "tag": tag, "count": 1, "subtags": {}};
                } else {
                    this.tags[tag].count = (this.tags[tag].count+1);
                }
              }

            });
          } else{
              //only one tag
              let tag = this.pages[page].tags;
              if(!this.tags_ex.includes(tag)){
                if(!this.lib.isProperty(this.tags,tag)){
                    this.tags[tag] = { "tag": tag, "count": 1, "subtags": {}};
                } else{
                    this.tags[tag].count = (this.tags[tag].count+1);
                }
              }
          }
        }
      });
    }

}
