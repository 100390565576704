import {Component,Injectable, Inject} from "@angular/core";


/******************LIBRARY FUNCTIONS**********************/

@Injectable()
export class Library{

      constructor() {
      }

      resized:any;

      //creates a new field with generic properties
      // addFields(parent,count,properties,keys,values,labels){
      //   console.log(values); //this is undefined, unused?
      //   for(var i=0; i < count; i++){
      //      parent.push(this.newObj(properties));
      //      if(keys){
      //        parent[i].key = keys[i];
      //        parent[i].value = values[keys[i]] ? values[keys[i]] : "";
      //        parent[i].type = labels[keys[i]].type;
      //      }
      //   }
      //   console.log(parent);
      //   return parent;
      // }




      addFields(parent,count,properties,keys,data,labels){

        for(var i=0; i < count; i++){
           parent.push(this.newObj(properties));
           if(keys){
             parent[i].key = keys[i];
             parent[i].data = data[keys[i]] ? data[keys[i]] : data[keys[i]].value ? data[keys[i]].value : "";

             parent[i].type = labels[keys[i]].type;
           }
        }
        return parent;
      }

      //returns length of keys in object
      objLength(object){
        return Object.keys(object).length;
      }


      //create a new instance of an object, avoids duplicate objects carrying same value
      newObj(object){
           return Object.assign({}, object);
      }

      //move an index within an array
      moveArray(arr, old_index, new_index) {
          while (old_index < 0) {
              old_index += arr.length;
          }
          while (new_index < 0) {
              new_index += arr.length;
          }
          if (new_index >= arr.length) {
              var k = new_index - arr.length;
              while ((k--) + 1) {
                  arr.push(undefined);
              }
          }
          arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
          return arr; // for testing purposes
      }

      //duplicates a new instance of an array
      duplicateArray(array) {
      let arr = [];
        array.forEach((x) => {
          arr.push(Object.assign({}, x));
        })
        return arr;
      }

      //delete index from array
      delete(index,array){
        return  array.splice(index,1);
       }

      //in case the above don't work, this is the solution for a deep copy, but is apparently quite slow?
      deepCopy(origObj){
        var newObj = origObj;
         if (origObj && typeof origObj === "object") {
             newObj = Object.prototype.toString.call(origObj) === "[object Array]" ? [] : {};
             for (var i in origObj) {
                 newObj[i] = this.deepCopy(origObj[i]);
             }
         }
         return newObj;
      }

      //returns true if it is an array
      isArray(value){
        return Array.isArray(value) && value.constructor === Array;
      }

      //returns true if it is an object
      isObject(value){
        return typeof value === 'object' && value.constructor !== Array;
      }

      isObjectEmpty(obj){
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
      }

      //returns if objects has property
      isProperty(obj,prop){
        if(this.isDefined(obj)){
          return obj.hasOwnProperty(prop);
        }
      }

      //returns true if defined
      isDefined(object){
        if(!object || typeof object === undefined){
          return false;
        } else{
          return true;
        }
      }

      //get target element
      getTargetElement(event){
        return event.target.localName;
      }

      capitaliseString(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      toTitleCase(str){
        return str.replace(/\w\S*/g, function(txt){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }

      checkReturnArray(obj,prop){
        return this.isProperty(obj,prop) ?  obj[prop] : [];
      }

      //combination - check array is defined, property exists, and that property has value
      checkNode(arr,prop,val){
        if(this.isDefined(arr)){
          if(this.isProperty(arr,prop) !== false){
            return arr[prop] === val;
          } else {
            return arr === val;
          }
        }
      }

      checkNodeArr(arr,prop,val){
        if(this.isDefined(arr)){
          if(this.isProperty(arr,prop) !== false){
            return arr[prop].indexOf(val);
          } else{
            return false;
          }
        }
      }

      inArray(arr,obj){
        if(this.isDefined(arr)){
          return arr.indexOf(obj);
        }
      }


      //get target (should work for all browsers)
      getTarget(obj) {
          var targ;
          var e=obj;
          if (e.target) targ = e.target;
          else if (e.srcElement) targ = e.srcElement;
          if (targ.nodeType == 3) // defeat Safari bug
              targ = targ.parentNode;
          return targ;
      }

      //traverse up parent nodes
      traverseUp(obj,c){
        for(var i = 0, count = c; i < count; i++){
         obj = obj.parentElement; if(i == (count-1)) return obj;
        }
      }

      //convert rgb to hex
      rgb2hex(rgb){
       rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
       return (rgb && rgb.length === 4) ? "#" +
        ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
      }


      //sort alphabetically
      sort(type,direction,arr,prop:any=false,filter:any = false){
      let res = [];
        switch(type){
          case 'numerically':
              switch(direction){
                case 'asc':
                  res =  arr.sort(function(a, b) {
                            a = eval('a.' + prop);
                            b = eval('b.' + prop);
                            return parseFloat(a) - parseFloat(b);
                         });
                break;
              }
          break;
        }
      }

      // sort1(type,direction,arr,prop:any=false,filter:any = false){
      //   let res =  arr.sort(function(a, b) {
      //                 a = eval('a.' + prop);
      //                 b = eval('b.' + prop);
      //                 return parseFloat(a) - parseFloat(b);
      //               });
      //   console.log(res);
      //   return res;
      // }

      /* Resize functions */

      doResize(){
        setTimeout(() => {
          let _t = this;
          //resize any box images to fit
          let parent = document.querySelectorAll('.box, .feed-img-container');

           [].forEach.call(parent, function(p) {
              let child = p.querySelectorAll('.box-img, .feed-img');
              if(child.length){
                 _t.onResizeFitChildEl(child[0],p,0);
              }
           });
        },);

      }


      onResizeEl(e) {
        clearTimeout(this.resized);
        this.resized = setTimeout(function(){
        }, 500);
      }

      //resize child image to parent container
      onResizeFitChildEl(child,parent,timeout = 500) {
        //clearTimeout(this.resized);
        let resized = setTimeout(function(){

          let ph = parent.offsetHeight, pw = parent.offsetWidth, ch = child.naturalHeight, cw = child.naturalWidth;


          let ratio = (cw/ch);
          let ncw: any = pw;
          let nch: any = (ncw / ratio);
          let diff:any = 0;

          if (ph > nch){
            diff = ph - nch;
            nch = (nch + diff);
            ncw = (nch * ratio);
          } else if(pw > ncw){
            diff = pw - ncw;
            ncw = (ncw + diff);
            nch = (ncw * ratio);
          }

          child.style.width = ncw + 'px';
          child.style.height = nch + 'px';

        }, timeout);
      }



      // onResizeFitChildEl(child,parent,timeout = 500,_t:any = this) {
      //
      //
      //   //clearTimeout(this.resized);
      //   this.resized = setTimeout(function(){
      //
      //     let ph = parent.offsetHeight, pw = parent.offsetWidth, ch = child.naturalHeight, cw = child.naturalWidth;
      //
      //     let ratio = 1; //if both height and width are the same, default to 1
      //     ratio = cw > ch ? (cw / ch) : (ch / cw);
      //
      //     let nch: any = (pw / ratio);
      //     let ncw: any = (nch / ratio);
      //     let diff: any = 0;
      //
      //     let nch_unit = 'px';
      //     let ncw_unit = 'px';
      //
      //     let vc: any = ((nch - ph) / 2);
      //
      //     //if child height greater than width
      //     if(ch > cw){
      //       //new child height is parent height
      //       nch = ph;
      //       //new child width is ratio of parent height
      //       ncw = (ph / ratio);
      //     } else if (cw > ch){
      //       //else use parent width
      //       ncw = pw;
      //       //and height in proportion to parent width ratio
      //       nch = (pw / ratio);
      //     } else {
      //       //default
      //       nch = nch;
      //       ncw = ncw;
      //     }
      //
      //     //Handle custom themes
      //     if(_t.theme == 'hero') {
      //       parent.style.height = ph + 'px';
      //       if(_t.classes){
      //         if(!_t.classes.includes('car-custom1')) {
      //           ncw = ncw/2;
      //           nch = nch/2;
      //         }
      //       }
      //     }
      //
      //     //Set minimum child height
      //     if(nch <= 470){
      //       nch = 470;
      //       ncw = 'auto';
      //       ncw_unit = '';
      //     }
      //
      //     //Center image vertically depending on new width and height
      //     vc = ((ph - nch) / 2);
      //
      //     child.style.width = ncw + ncw_unit;
      //     child.style.height = nch + nch_unit;
      //     child.style.marginTop = vc + 'px';
      //
      //   }, timeout);
      // }

      //make a random selection
      randomChoice(choices) {
        var index = Math.floor(Math.random() * choices.length);
        return choices[index];
      }

      //get elements Position
      getElPosition(elem) {
          var left = 0,
              top = 0;

          do {
              left += elem.offsetLeft-elem.scrollLeft;
              top += elem.offsetTop-elem.scrollTop;
          } while ( elem = elem.offsetParent );

          return [ left, top ];
      }

      contains2(target, pattern){
          var value = 0;
          pattern.forEach(function(word){
            value = value + target.includes(word);
          });
          return (value === 1)
      }


      contains(target, pattern){
          var value = 0;
          pattern.forEach(function(word){
            value = value + target.includes(word);
          });
          return value;
      }

      //compares values of one array with another
      //if any one is found, we return true
      containsAny(needles, haystack){
        let r = false;
        needles.forEach(needle => {
            if(haystack.indexOf(needle) !== -1) r = true;
        });
        return r;
      }


      toJSON(str){
        return JSON.parse(str);
      }


      getElementY(query) {
        return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
      }

      getElementY2(el) {
        if(this.isDefined(el)){
          return window.pageYOffset + el.getBoundingClientRect().top;
        }else {
          return 0;
        }
      }

      /* Scroll functions */

      isScrolledIntoView(el)
      {

        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        var offset = 100;

        // Only completely visible elements return true:
        var isVisible = (elemTop >= 0) && ((elemBottom) <= (window.innerHeight + offset));

        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        //There's the possibility the last element is not large enough to be detected so we also detect if we have scrolled to the bottom in case
        if(this.isScrolledToBottom() === true) isVisible = true;
        return isVisible;
      }

      //TO DO : Too many fucntions for whether checking elements are scrolled
      //Check if element is scrolled with 'is-scrolled' class
      checkForScrolled(){
        let els = document.querySelectorAll('.is-scrolled');
        els.forEach(el =>{
          let box = el.getBoundingClientRect();
          let isscrolled = box.top < window.innerHeight && box.bottom >= 0;
          if(isscrolled){
            el.classList.add('scrolled');
          } else {
            el.classList.remove('scrolled');
          }
        });
      }

      //Check for 'scroll-in' elements and apply animation effects
      checkForScrolledIn(alreadyscrolled = false){
        let els = document.querySelectorAll('.scroll-in');
        els.forEach(el =>{
          let box = el.getBoundingClientRect();
          //todo:this doesnt quite work for mobile
          let isscrolled = box.top < window.innerHeight && box.bottom >= 0;
          if(isscrolled || alreadyscrolled){
            el.classList.contains('scroll-fade') ? el.classList.add('scroll-in-animate-fade') : el.classList.add('scroll-in-animate');
          } else {
            if(!el.classList.contains('scroll-once')) el.classList.remove('scroll-in-animate', 'scroll-in-animate-fade');
          }
        });
      }

      //Check if element is scrolled
      isScrolled(el,scrolledEl:any){

        let r = false;
        let offset = 10;
        let top = this.getElPosition(el)[1];
        let bottom = (top + el.offsetHeight);
        var elId = el.id;

        //let isScrolled = this.scrollpos >= top  && this.scrollpos <= (top+offset);
        let isScrolled = this.isScrolledIntoView(el);

        if(isScrolled === true){
           scrolledEl = '';
           if(el.hasAttribute('data-sidenav')) scrolledEl = el.getAttribute('data-sidenav');
           r = true;
        }
        return r;
      }

      scrollToTop(){
          window.scrollTo({top: 0, behavior: 'smooth'});
      }

      isScrolledToBottom(offset = 0){
        if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - offset) {
          return true;
        }
        return false;
      }

      //Smooth scrolling effect
      // scrollEffect(dir){
      //
      //   let els = document.querySelectorAll('.mainrow');
      //
      //     // Clear our timeout throughout the scroll
      //     window.clearTimeout( this.isScrolling );
      //
      //     let _t = this;
      //
      //     // Set a timeout to run after scrolling ends
      //     this.isScrolling = setTimeout(function() {
      //       if(els.length){
      //         els.forEach((el,i) =>{
      //           if(typeof el !== null){
      //             let box = el.getBoundingClientRect();
      //             var topperc = ((window.innerHeight - box.top) / window.innerHeight) * 100;
      //             var botperc = ((box.bottom - window.innerHeight) / window.innerHeight) * 100;
      //             let isscrolled = (topperc >= 30 && topperc < 100 && dir == 'down') || (topperc <= 140 && topperc > 100 && dir == 'up');
      //
      //             if(isscrolled){
      //               el.classList.add('scrolled-in-view');
      //               setTimeout(()=>{
      //                 _t.lib.scrollSmooth('.scrolled-in-view',500);
      //               },300);
      //             } else {
      //               el.classList.remove('scrolled-in-view');
      //             }
      //           }
      //         });
      //       }
      //     }, 400);
      // }

      scrollSmooth(element, duration) {

      	var startingY = window.pageYOffset;
        var elementY = this.getElementY(element);

        // If element is close to page's bottom then window will scroll only to some position above the element.
        var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;

      	var diff = targetY - startingY;
        // Easing function: easeInOutCubic
        // From: https://gist.github.com/gre/1650294
        var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
        var start;

        if (!diff) return

      	// Bootstrap our animation - it will get called right before next frame shall be rendered.
      	window.requestAnimationFrame(function step(timestamp) {
          if (!start) start = timestamp
          // Elapsed miliseconds since start of scrolling.
          var time = timestamp - start
      		// Get percent of completion in range [0, 1].
          var percent = Math.min(time / duration, 1)
          // Apply the easing.
          // It can cause bad-looking slow frames in browser performance tool, so be careful.
          percent = easing(percent)

          window.scrollTo(0, startingY + diff * percent)

      		// Proceed with animation as long as we wanted it to.
          if (time < duration) {
            window.requestAnimationFrame(step)
          }
        })
      }

      //Scroll to function
      scrollTo(id,event,scrollpos){
        let scrollcontent = document.querySelectorAll('.scroll-content')[0];
        let el = document.getElementById(id);
        let elpos = this.getElPosition(el);
        let scrollPos = elpos[1];
        scrollcontent.setAttribute('scroll',scrollPos.toString());
        scrollcontent.setAttribute('style','transform: translate3d(0px,-'+scrollPos+'px,0px)!important');
        scrollpos = scrollPos;
      }

      //Return scrolled amount percentage
      // getScrolled(){
      //   let s = Math.round(this.scrollpos / this.scrollHeight * 100);
      //   return s;
      // }

      //Check if footer scrolled to view
      isFooterScrolled(el){
        let box = el.getBoundingClientRect();
        let isscrolled = box.top < window.innerHeight && box.bottom >= 0;
        return false;
      }


      fixSvg(): void {
        //only apply this fix to IE and firefox
        //if(navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1 || navigator.userAgent.indexOf("Firefox") > -1 || navigator.userAgent.indexOf("Edge") > -1){
        //window.getComputedStyle(elem)
        let _t = this;
        setTimeout(function(){
        let css = '';
          Array.from(document.getElementsByClassName("svg")).forEach(function(item) {
              let style = window.getComputedStyle(item);
              let bi = style.backgroundImage.split('utf8,');
              //let bii = bi[1].split('")');
              if(_t.isDefined(bi[1])){
                //let svg = bi[1].replace('")', '');
                let svg = navigator.userAgent.indexOf('Edge') > -1 ? bi[1].replace(')', '') : bi[1].replace('")', '');
                //let svg = bi[1].replace(')', '');

                let svgs = svg.replace(/\\/g, '');
                var svgen = encodeURIComponent(svgs);
                var svgen1 = svgen.replace(/%25/g, "%");
                var svgen2 = svgen1.replace(/'/g, "%27");

                item.setAttribute('style','background-image:url("data:image/svg+xml,'+svgen2+'")');
                //let backimgurl = 'url("data:image/svg+xml,'+svgen2+'")';
                //let backimg = navigator.userAgent.indexOf('Edge') > -1 ? backimgurl.substring(0, backimgurl.length - 1) : 'url("data:image/svg+xml,'+svgen2+'")';
                //let itm = <HTMLElement>item; itm.style.backgroundImage = backimg;

                //str.substring(0, str.length - 1);

                //if parent contains icon-btn class, then svg is part of icon-list
                if(Array.from(item.parentElement.classList).indexOf('icon-btn') > -1){

                   //do hover fixes
                  let hovcol = 'fff';
                  if(Array.from(item.parentElement.parentElement.classList).indexOf('secondary-hover') > -1 || Array.from(item.parentElement.parentElement.classList).indexOf('primary-hover') > -1){
                    let row = _t.traverseUp(item,9);
                    let rowstyle = window.getComputedStyle(row);
                    hovcol = _t.rgb2hex(rowstyle.backgroundColor).replace('#','');
                  }
                  let svgenhover =  svgen2.replace(/stroke%3A.*%3B/, 'stroke%3A%23'+hovcol+'%3B');
                  let classd = '.'+item.className.split(/[ ]+/).join('.');
                  css += '.icon-btn:hover '+classd+' { background-image:url("data:image/svg+xml,'+svgenhover+'")!important; }';
                }
              }

          });
        var style = document.createElement('style');
        style.appendChild(document.createTextNode(css));
        document.getElementsByTagName('head')[0].appendChild(style);
      },300);
    }

    //Lazy load content
    lazyLoad(el){
     let r = false;
     if(el.attributes.loaded){
       let loaded = el.attributes.loaded.value;
       let isScrolled = this.isScrolledIntoView(el);
       if(isScrolled && loaded == 'false'){
          el.setAttribute('loaded',true);
          r = true;
          //resize anything;
          this.doResize();
          return r;
        }
      }
      return r;
    }





}
