import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import {Library} from '../../app/app.library';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';



@Component({
  selector: 'comp-contact',
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.scss'],
  providers:[Library]
})
export class ContactComponent {

  @Input() el: any;
  @Output() callback = new EventEmitter();

  //form:any = {};
  sent:string = '';
  data:any;
  url:string = window.location.href;
  canSubmit:boolean = false;
  hideForm:boolean = false;
  pendingSend:boolean = false;

  form:any = { "service": ""};
  form2:any = { "service": ""};
  subscribed:string = '';
  termsAgreed:boolean = false;

  constructor(public lib: Library,private http: HttpClient){}

    ngAfterContentInit(){
      this.url = window.location.href;
    }

    /* Callback */

    doCallback(a,p){
      this.callback.emit({'a':a,'p':p});
    }

    /* Contact functions  */

    onSubmit(action:any=false,link:string='',event:any = false){

      this.hideForm = true;
      this.pendingSend = true;

      let target = this.lib.getTarget(event);
      let nofields = target.elements.length-1;
      const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
      };
      let creds = this.form;
      creds.action = action;
      let pass = true;
      let msg = 'Please complete the required fields';
      //let required = ['name', 'firstname','surname','email','email2','address','postcode','phone', 'propertyno'];
      let params = {};
      var em: string = '';
      switch(action){
        case 'submitcontact':
          for(let i=0,len =nofields; i < nofields; i++){
            let el = target.elements[i];
            let field = el.name;
            creds[field] = el.value;

            if(el.required){

                if(creds[field] == '' || creds[field] == 'undefined'){
                  //typed but then cleared, so appended to form but value is blank
                  console.log(el.name);
                  pass = false;
                }

                if(el.value == ''){
                  alert('Required fields cannot be blank');
                  pass = false;
                  return false;
                }

                //Make sure message is not empty
                if(el.name == 'message' && el.value.length == 0){
                  alert('Message cannot be empty');
                  return false;
                }
              }

              if(el.value !== ''){
                let verified = this.verify(el.value,el.name);
                if(!verified){
                  alert('Incorrect format. Please verify your '  + el.name);
                  return false;
                }
              }

          }
          let url = window.location.pathname.split( '/' );
          let last = url.length-1;
          let hash = window.location.hash;
          creds['campaign'] = url[last];
          let mailpath = link !== '' ? link : 'mailer.php';


          if(!pass){
            console.log('pass is false');
            alert(msg);
            return false;
         }

          new Promise(resolve => {
            this.http.post('assets/mail/'+mailpath, creds, httpOptions)
              .subscribe(
              res => {
                console.log(res);
                this.data = res;
                resolve(this.data);
                this.sent = 'true';
                this.hideForm = true;
                this.pendingSend = false;
                console.log(this.data);
              },
              err => this.responseFail(err),
              () => console.log('Post request complete')
              );
           });
           params = {"alias": 'contact', "action": "contactform", "event": event};
           this.emit(params);
        break;
      }
    }

    doForm(e,k,t:any=''){
      console.log(e);
      if(t !== 'textarea') e = e.trim();
      if(k == 'email' || k == 'email2') e = e.toLowerCase();
      if(k == 'postcode') e = e.trim().toUpperCase();
      this.form[k]=e;
      console.log(this.form);
    }

    emit(params){
      this.callback.emit(params);
    }

    verify(input,validation){
      let v:any;
      let validations = ['name','phone','email','postcode'];
      let check:string = '';
      //check input needs to be validated by matching the key with any of the validations
      validations.forEach(v =>{
        if(validation.indexOf(v)!== -1) check = v;
      });
      if(check !== ''){
        switch(check){
          case 'name':
          v = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
          break;
          case 'phone':
          v = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
          break;
          case 'email':
          v = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          break;
          case 'postcode':
          v = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
          break;
        }
        return v.test(input);
      } else {
        return true;
      }

    }

    responseFail(err){
      this.pendingSend = false;
      this.sent = 'false';
      console.log(err);
    }

    handleCorrectCaptcha(e){
      this.canSubmit = true;
      //console.log(e);
    }



}
