import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation, ViewChild, ViewChildren, HostListener,ChangeDetectorRef, HostBinding, QueryList } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';

import { MasonryGridComponent } from '../MasonryGridComp/masonrygrid.component';
import { SideNavComponent } from '../SideNavComp/sidenav.component';

import { Observable, of, Subject  } from 'rxjs';
import { map } from 'rxjs/operators';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/map';

import { CookieService } from 'ngx-cookie-service';

import {EvalPipe} from '../../pipes/eval.pipe/eval.pipe';
import {KeysPipe} from '../../pipes/keys.pipe/keys.pipe';
import {AliasPipe} from '../../pipes/alias.pipe/alias.pipe';
import {FormatPipe} from '../../pipes/format.pipe/format.pipe';

import {Library} from '../../app/app.library';

@Component({
  selector: 'casestudy-template',
  templateUrl: 'casestudytemplate.component.html',
  styleUrls: ['casestudytemplate.component.scss'],
  providers:[Library],
  encapsulation: ViewEncapsulation.None
})

export class CaseStudyTemplateComponent {

  @ViewChildren('masonryGridComp') grids: QueryList<MasonryGridComponent>;
  @ViewChild(SideNavComponent) sideNavComp: SideNavComponent;
   @ViewChild('testimonialComp') testimonialComp;

  private MasonryGrid: MasonryGridComponent;

  mouseX:number = 0;
  mouseY:number = 0;

  @Input() content: any;
  @Input() el: any;
  @Input() t: any;
  @Input() get: any;
  @Input() casestudy: string = '';
  @Output() callback = new EventEmitter();

  views:number = 0;
  popupInterest:boolean = false;
  popupInterestForm:boolean = false;
  popupInterestFormSubmitted:boolean = false;
  interestEmail:string = '';
  relatedCaseStudies:number = 0;
  topNavHover:boolean = false;
  showTopNavHover:boolean = false;
  testimonialString:string = '';

  @HostListener("window:mousewheel", ["$event"]) onWindowScroll(e:any) {
    this.checkTopNav();
  }


  @HostListener("window:mousemove", ["$event"]) onMouseMove(e:any) {
    let mouseX = e.clientX;
    let mouseY = e.clientY;
    this.mouseX = mouseX;
    this.mouseY = mouseY;
    const screenWidth = window.outerWidth;
    const offset = 500;
  }

  constructor(public lib: Library, private http: HttpClient, private cookieService: CookieService, private ref:ChangeDetectorRef){
  }

  ngAfterContentInit(){
    this.views++;
  }

  ngAfterViewInit(){
    //Begin loading case study
    let _t =this;
    setTimeout(async ()=>{
        //Loop through each masonry grid component on the page
        console.log(this.grids);
        this.grids.forEach(async (grid) =>{
          //If the grid component does not have related property, this is the main one
          if(!grid.related){
            _t.casestudy = grid.casestudy;
            grid.doMasonry(grid.masonry,false);
          }else{
            //Otherwise set up related grid component
            await grid.doRelated();
            setTimeout(() => {
              this.relatedCaseStudies = grid.list.length;
            },5000);
          }
        });
    },);
  }

  //Navigate to a different page but keep track of how many times the case study has been viewed
  changePage(alias, mid=0, smid=[], event:any=0, parent:any=false, get:any = false){
    this.views++;
    let params = {"alias": alias, "action": "changepage", "event": event, "get" : get};
    this.emit(params);
  }

  //Navigate to this page and configure
  navPage(dir){

    let page = this.t.setPage;
    let pageFields = page.fields;
    let casestudies = this.t.casestudies;
    let casestudiesFull = this.t.casestudiesFull;
    let cid = casestudies.indexOf(page);

    switch(dir){
      case 'prev':
        cid = cid == 0 ? casestudies.length-1 : cid-1;
      break;
      case 'next':
         cid =  cid == casestudies.length-1 ? 0 : cid+1;
      break;
    }

    let casestudyGridEl = casestudiesFull[cid].fields[2];
    let testimonial = casestudiesFull[cid].fields[3];
    let casestudy = casestudies[cid];
    this.loadCaseStudy(casestudy,casestudyGridEl,testimonial);
  }

  //Emit any callbacks
  emit(params){
    this.callback.emit(params);
  }

  //Handle callbacks
  handleCallback(params){
    switch(params.action){
      case "scrollto":
        this.scrollTo(params.alias,params.event);
      break;
      case "changePage":
        let casestudy = params.p[0];
        let casestudies = this.t.casestudies;
        let casestudiesFull = this.t.casestudiesFull;
        let cid = casestudies.indexOf(casestudy);
        let casestudyGridEl = casestudiesFull[cid].fields[2];
        let testimonial = casestudiesFull[cid].fields[3];
        this.loadCaseStudy(casestudy,casestudyGridEl,testimonial);
      break;
    }
  }

  //Load case study page
  loadCaseStudy(casestudy,casestudyGridEl,testimonial){
    //First call main changePage process to set up page first
    this.changePage(casestudy);
    //Then do grid components
    this.grids.forEach(async (grid) =>{
        setTimeout(async ()=>{
          if(!grid.related){
            //If main component, reset main parameters
            grid.limit = grid.limitDefault;
            grid.start = 0;
            grid.loadFinished = false;
            grid.casestudy = casestudy.split('casestudy-')[1];
            //Redo main data list for case study
            grid.doList(casestudyGridEl.value);
            //Then do the masonry layout using list data
            grid.doMasonry(grid.masonry,false);
          }else{
            //Handle related grid component
            await grid.doRelated();
            setTimeout(() => {
              this.relatedCaseStudies = grid.list.length;
            },);
          }
        },);
    });
  }

  loadTestimonial(content){
    return JSON.parse(content.fields[3].value);
  }

  scrollTo(id,event){
    this.lib.scrollSmooth('#'+id,1500);
  }

  checkTopNav(){
    if(this.t.scrollpos > 100){
      this.t.topNavHover = false;
      this.t.showTopNavHover = true;
    }else{
      this.t.showTopNavHover = false;
    }
  }

}
