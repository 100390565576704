import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnInit, QueryList, AfterViewInit, OnChanges, ViewChild, ViewChildren, SimpleChanges, Injectable, ChangeDetectorRef, HostListener,  NgZone } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';

import { MasonryGridComponent } from '../MasonryGridComp/masonrygrid.component';
import { ListComponent } from '../ListComp/list.component';

import { Observable, of, Subject  } from 'rxjs';
import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';

import {EvalPipe} from '../../pipes/eval.pipe/eval.pipe';
import {KeysPipe} from '../../pipes/keys.pipe/keys.pipe';
import {AliasPipe} from '../../pipes/alias.pipe/alias.pipe';
import {FormatPipe} from '../../pipes/format.pipe/format.pipe';

import {Library} from '../../app/app.library';

@Component({
  selector: 'child-template',
  templateUrl: 'childtemplate.component.html',
  styleUrls: ['childtemplate.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[Library]
})
export class ChildTemplateComponent implements OnChanges, AfterViewInit {

  @ViewChildren('masonryGridComp') grids: QueryList<MasonryGridComponent>;
  @ViewChild('listComp') list: QueryList<ListComponent>;
  private MasonryGrid: MasonryGridComponent;
  //@ViewChild(MasonryGridComponent) masonry: MasonryGridComponent;


  @Input() type: string;
  @Input() tag: string;
  @Input() el: any;
  @Input() idx: number;
  @Input() eid: number;
  @Input() cid: number;
  @Input() rid: number;
  @Input() pages: any;
  @Input() setPage: string;
  @Input() get: any = false;
  @Input() parent: any = false;
  //@Input() masonryGrid: any;

  //@Output() callback:EventEmitter<any> = new EventEmitter()

  @Output() callback = new EventEmitter();

  resized:any;
  data;
  deepget:any;
  callbackParams:any;
  isCallback:boolean = false;

 constructor(public lib: Library, private http: HttpClient, private ref: ChangeDetectorRef, private _ngZone: NgZone){
  }

  ngOnInit(){
    this.handleGet();
  }

  ngOnChanges (changes: SimpleChanges) {
  } // end onChanges

  ngAfterContentInit(){
    setTimeout(()=>{
      this.lib.doResize();
    },1000);
  }

  ngAfterViewInit(){
  }

  ngOnDestroy() {
   window['angularComponent'] = null;
  }

    handleGet(){
      //If get is not false, catch it and deep copy
      if(!!this.get){
        const get = this.get.toString();
        this.deepget = this.lib.deepCopy(get);
      }
    }

    emit(params){
      this.callback.emit(params);
    }

    handleCallback(e){
      let action = e.a; this.callbackParams = e.p;
      this.isCallback = true;
      eval('this.'+action+'()');
    }

    //Dynamic callback actions
    changePage(alias:string='', mid:number=0, smid:any=[], event:any=0, get:any=false){
      if(this.isCallback){ let p = this.callbackParams; alias = p[0]; mid = p[1]; smid = p[2]; event = p[3]; get = p[4]; }
      let params = {"alias": alias, "action": "changepage", "event": event, "get": get};
      this.emit(params);
    }


  }
