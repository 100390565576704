import { Pipe, PipeTransform } from '@angular/core';
/*
 * Evaluates a string which contains a computed property path
*/
@Pipe({name: 'eval'})
export class EvalPipe implements PipeTransform {
  transform(parent: any, key: string, target:any = {}): any {
    //parent - the main object we are referencing
    //key - the property of which its value is the string to be evaluated
    //target - the object being evaluated, 'target' should be in the string
    //console.log(parent);
    if(key == 'false'){
      //console.log(eval(parent));

      return eval(parent);
    }else{
      //console.log(parent);
      //console.log(key);
      return eval(parent[key]);
    }
  }
}
