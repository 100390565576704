import { Component, OnInit, Input, ElementRef, Output, OnChanges, EventEmitter, SimpleChanges, HostBinding, Directive, Injectable, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';

/*
This directive loads directives dynamically that are placed in the attributes field of JSON elements
Be sure to include the directives themselves in app.components and use hostbinding so they can be picked up here
*/

@Directive({
  selector: '[attrDir]'
})
export class AttrDir {
  @Input('attrDir') attributes: any;
  @Input('noDir') noDir: boolean;
  @Input('comp') comp: any = false;
  @Input('t') t: any; //'this' is carried from AppComponent

  constructor(private el: ElementRef) {
      setTimeout(()=>{

        if(this.attributes){
          console.log(this.attributes);
          let attrs = this.attributes.split(',');
          attrs.forEach(attr =>{

            if(!this.noDir){
              let at = 'data'+attr+'Dir';
              let att = document.createAttribute(at);
              el.nativeElement.setAttributeNode(att);
              this.t[at].ngOnInit();
            }else{

              let at = attr.split('=');
              let att = document.createAttribute(at[0]);
              att.value =  at[1];
              el.nativeElement.setAttributeNode(att);
              if(!!this.comp) this.comp[at[0]] = at[1];
            }




          });
        }
      },);
   }
}
