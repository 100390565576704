import {Config} from './app.config';
//import {Definitions} from './app.definitions';
//import {Nodes} from './app.nodes';
import {Injectable, Inject} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from 'rxjs';

@Injectable()
export class Service {
  data: any; progress: number; progressObserver:any;

  constructor(private http: HttpClient) {
    this.http = http;
    //progress
    // this.progress = Observable.create(observer => {
    //   this.progressObserver = observer
    // }).share();

  }

  //load data from json
  loadJSON(path){
    console.log(path);
    return new Promise(resolve => {
      this.http.get('./assets/'+path+'.json').subscribe(
      (res) => {
        this.data = res;
        resolve(this.data);
      },
      (error) => console.log(error),
      () => console.log());
    });
  }

  loadJSON2(path,headers){
    return new Promise(resolve => {
      this.http.get('./assets/'+path+'.json',{ headers: headers }).subscribe(
      (res) => {
        this.data = res;
        resolve(this.data);
      },
      (error) => console.log("error : " + error),
      () => console.log());
    });
  }

  //return config
  getConfig(){
	 return Config;
  }

  //return definitions
  // getDefinitions(){
	//  return Definitions;
  // }
  //
  // //return definitions
  // getNodes(){
	//  return Nodes;
  // }

  //perform a POST or GET request
  doRequest(event, data, header, server, method) {

     // prevent any conflicts
     //event.preventDefault(); //event doesn't appear to be carried through and is empty when coming from actions.requestServer, look into, do we need event? this could have been carried over from an older structure

     //prepare credentials, convert json data to string, unless data is a file
     var creds:any;

     if(method !=='file'){
       creds = JSON.stringify(data);
     } else{
       creds = data;
     }

     //append correct headers
     //var headers = new Headers();
     //headers.append('Content-Type', header);


     const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  header
      })
    };

     if(method === 'post'){

           //do post request
           return new Promise(resolve => {
             this.http.post(server, creds, httpOptions)
               .subscribe(
               res => {
                 this.data = res;
                 resolve(this.data);
               },
               err => this.responseFail(err),
               () => console.log('Post request complete')
               );
            });

      } else if (method === 'get'){

            return new Promise(resolve => {
               this.http.get(server).subscribe(res => {
                 this.data = res;
                 resolve(this.data);
               });
             });
      } else if (method === 'file'){

        return Observable.create(observer => {
              let formData: FormData = new FormData(),
              xhr: XMLHttpRequest = new XMLHttpRequest();

              let files = creds[0];
              let params = creds[1];
              let progress = 0;

              for (let i = 0; i < files.length; i++) {
                formData.append("uploads[]", files[i], files[i].name);
              }
              console.log('params');
              console.log(params);

              //data
              formData.append('userid', params.parent);
              formData.append('projectid', params.id);

              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    observer.next(JSON.parse(xhr.response));
                    observer.complete();
                  } else {
                    observer.error(xhr.response);
                  }
                }
              };

              xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);

                this.progressObserver.next(this.progress);
              };

              xhr.open('POST', server, true);
              //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
              //xhr.setRequestHeader("Content-Type","multipart/form-data");
              xhr.send(formData);
            });
      }

  }

  //response failed, todo: error handler
  responseFail(err){
     console.log('error');
  }


}
