import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {Library} from '../../app/app.library';

@Component({
  selector: 'comp-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss'],
  providers:[Library],
  animations: [
    trigger('search', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class SearchComponent implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Input() pages:any;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() callback = new EventEmitter();

  query: string = '';
  pagindex:number = 0;
  pagination:any = [];
  filteredpages:any = [];
  sortedpages:any = [];
  maxresults:number = 5;
  feedDisplay:boolean =  false;
  filters: any = [];
  tags: any = {};
  groups: any = [];
  groups_select: any = [];
  tags_ex: string = 'local group';
  callbackParams:any;

  constructor(public lib: Library) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.visible){
      this.getFilteredPages();
      this.createTags();
      this.createGroups();
    }
  }

  close() {
    this.visible = false;
    this.visibleChange.emit();
  }

  /* Callbacks */

  handleCallback(a,p){
    let action = a; this.callbackParams = p;
    eval('this.'+action+'()');
  }

  changePage(alias:string='', mid:number=0, smid:any=[], event:any=0, get:any=false){
    let p = this.callbackParams; alias = p[0]; mid = p[1]; smid = p[2]; event = p[3]; get = p[4];
    let params = {"alias": alias, "action": "changepage", "event": event, "get": get};
    this.emit(params);
  }

  emit(params){
    this.callback.emit(params);
    this.close();
  }

  /* Search */

  querySearch(){
    this.getFilteredPages();
  }

  /* Pagination */

  setPagIndex(idx){
    this.pagindex = idx;
  }

  navPag(dir){
    let d = this.pagination; var sid = this.pagindex;
    dir ? sid < (d.length-1) ? this.pagindex++ : sid : sid > 0 ? this.pagindex-- : sid;
  }

  /* Pagination */

  doPagination(l = this.filteredpages){
    //set defaults
    let i = 0;let section = []; let p = [];
    setTimeout(()=>{
      //for each page
      l.forEach(page=>{
       //if(page.value.group == this.el.dataset.trim()){
       //if(page.value.published){
         //push page into section array
         section.push(page);
          //if only one section because pages less than max results
          if(l.length <= this.maxresults){
             p[0]=section;
          }else{
            //if last, push final section to pagination array
            if(i == (l.length - 1)){
                  p.push(section);
            } else {
              //if index not maxresult nor 0
              if(i % this.maxresults == 0 && i !== 0){
                  //push section then reset
                  p.push(section);
                  section = [];
              }
            }
          }
          i++;
      //  }
      });
      //copy pagination array to variable
      this.pagination = this.lib.deepCopy(p);
      //display feed
      this.feedDisplay = true;
    },);
  }


  /* Filter */

  getFilteredPages(){
    //set defaults
    this.filteredpages = []; this.pagination = [];let i =0; this.pagindex = 0;
    //hide feed
    this.feedDisplay = false;
    //filter feed pages if either from a select group or from pages
    let feedpages = Object.keys(this.pages).filter(p => this.groups_select.length > 0 ? this.groups_select.includes(this.pages[p].group): this.pages[p]).map(p => { return p; });
    //loop through each feed page
    feedpages.forEach(page =>{
        //set feed defaults
        let tags = this.pages[page].tags ? this.pages[page].tags : ''; let title = this.pages[page].title; let idx = i; let group =  this.pages[page].group;
        //if any matches push as filtered feed page
        if(this.filterCheck(tags,title,idx,group)) this.filteredpages.push({'key': page, 'value': this.pages[page]});
        //do next
        i++;
        if(i == feedpages.length) { this.filteredpages.reverse(); this.doPagination(); }
    });
  }

  //Toggle search filter
  filterSelection(event,type){
    //Get filter term
    let e = event.target.name.trim();
    if (event.target.checked) {
          //If filter term is not part of group add it
          if (this[type].indexOf(e) < 0) {
                this[type].push(e);
          }
     } else {
           //If it is, then remove it
           if (this[type].indexOf(e) > -1)
            {
                this[type].splice(this[type].indexOf(e), 1);
            }
    }
    //Update filter pages with the new filters
    this.getFilteredPages();
  }

  filterCheck(tags,title,idx,group){

    //If no query, filters or selected groups return false
    if(this.query == '' && this.filters.length == 0 && this.groups_select.length == 0){
      return false;
    } else {

      //Otherwise split tagsand set defaults
      let tgs = tags.split(',');
      let tagcheck:any = [];
      //For each tag, if exists on list of filters, push to an array
      tgs.forEach(t => { tagcheck.push(this.filters.indexOf(t.trim()) === -1 ? -1 : 0); });

      //Check 1 - Check if query matches with tags
      let check1 = new RegExp("\\b" + this.query.toLowerCase() + "\\b").test(tags.toLowerCase());

      //Check 2 - Check if query matches with title
      let check2 = new RegExp("\\b" + this.query.toLowerCase() + "\\b").test(title.toLowerCase());

      //Check 3 - Check if query matches with group
      let check3 = new RegExp("\\b" + this.query.toLowerCase() + "\\b").test(group.toLowerCase());

      //Return boolean for a variety of checks and conditions
      let r = (tagcheck.indexOf(0) > -1 && ((check1) || (check2) || (check3))) || (this.filters.length == 0 && this.query == '') || (this.filters.length == 0 && (check1 || check2 || check3));

      return r;
    }
  }



  /* Tags */

  /* Setup tags for selection and counts */

  createTags(){
    this.tags = [];
    //Loop through each page
    Object.keys(this.pages).forEach(page => {
        //If page contains tags
        if(this.pages[page].tags.indexOf(',')){
          //Convert tags into an array
          let tags = this.pages[page].tags.split(',');
          //For each tag
          tags.forEach(tag => {
            //If tag does not match with excluded set tags
            if(!this.tags_ex.includes(tag)){
              //If page tag is not on main tags array add it
              if(!this.lib.isProperty(this.tags,tag.trim())){
                  this.tags[tag.trim()] = { "tag": tag.trim(), "count": 1, "subtags": {}};
              } else {
                  //Increase count of how many pages contain this tag
                  this.tags[tag.trim()].count = (this.tags[tag.trim()].count+1);
              }
            }
          });
        } else {
            //Same again if there is only one tag
            let tag = this.pages[page].tags;
            if(!this.tags_ex.includes(tag)){
              if(!this.lib.isProperty(this.tags,tag)){
                  this.tags[tag] = { "tag": tag, "count": 1, "subtags": {}};
              } else{
                  this.tags[tag].count = (this.tags[tag].count+1);
              }
            }
        }
    });
  }


  /* Groups */
  createGroups(){
    this.groups = [];
    //Loop through pages
    Object.keys(this.pages).forEach(page => {
      //Get group of page
      let group = this.pages[page].group;
      //If this isnt on the group array, add it
      if(!this.groups.includes(group)){
        this.groups.push(group);
      }
    });
  }


}
