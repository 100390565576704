
<!-- <p>mouseY: {{mouseY}} mouseX: {{mouseX}}</p> -->

<!--Side Navigation -->

<!-- <comp-sidenav (callback)="handleCallback($event)"></comp-sidenav> -->

<!-- End of Side Navigation -->

<!-- Top Navigation -->


<div *ngIf="t.showTopNavHover == true" id="topNavigation" class="top-navigation" [ngClass]="t.topNavHover ? 'on' : ''" (mouseleave)="t.topNavHover=false">
  <!-- <div class="icon-wrap">
    <img [hidden]="t.scrollpos > 600" id="arrowUp" (click)="changePage('our-work')" (mouseenter)="t.topNavHover=true" src="./assets/icons/{{t.topNavHover ? 'arrow-up-white' : 'arrow-up-outline'}}.svg" />
    <img [hidden]="t.scrollpos < 600" id="arrowUpPrimary" (click)="changePage('our-work')" (mouseenter)="t.topNavHover=true"  src="./assets/icons/{{t.topNavHover ? 'arrow-up-white' : 'arrow-up-outline'}}.svg" />
  </div>
  <p [hidden]="!t.topNavHover" class="text-white">Return to work examples</p> -->
</div>

<!-- End of top navigation -->


 <div *ngIf="content.fields[1].value !== ''" id="row0" class="casestudy-item row row1 marg-vert-40 rel v-align-parent container-fixed center background-primary text-white-section">
   <div class="cols col-1 col1 tablet-full-width width-70">
     <div class="block container-fixed float-left">

       <div class="pad-75">
         <!--heading-->
         <div *ngIf="content.fields[1].value !== ''" id="problem" class="mainrow element row row-1-col-2-el-0" data-sidenav="problem">
           <div class="element-wrapper">
             <h1 class="heading">The challenge</h1>
           </div>
         </div>

         <!--copy-->
         <div *ngIf="content.fields[1].value !== ''" class="element row-1-col-2-el-0">
           <div class="element-wrapper center text-left pad-horiz-20">
               <div *ngIf="content.fields[0].value != ''" class="content subhead italic" [innerHTML]="content.fields[0].value"></div>
               <p class="content text-center" [innerHTML]="content.fields[1].value"></p>
           </div>
         </div>

        </div>

        </div>
      </div>
   </div>


   <div *ngIf="content.fields[4].value !== ''" id="row1" class="casestudy-item row row1 marg-vert-40 rel v-align-parent container-fixed center background-secondary">
     <div class="cols col-1 col1 tablet-full-width width-70">
       <div class="block container-fixed float-left">

         <div class="pad-75">


           <!--heading-->
           <div *ngIf="content.fields[4].value !== ''" id="solution" class="row element row-1-col-2-el-0" data-sidenav="solution">
             <div class="element-wrapper">
               <h1 class="heading text-primary">The solution</h1>
             </div>
           </div>

           <!--copy-->
           <div *ngIf="content.fields[4].value !== ''" class="element row-1-col-2-el-0">
             <div class="element-wrapper center text-left pad-horiz-20">
                 <p class="content text-darkgrey text-center" [innerHTML]="content.fields[4].value"></p>
             </div>
           </div>

          </div>

          </div>
        </div>
     </div>


       <div id="row2" class="casestudy-item row row1 marg-vert-40 rel v-align-parent container-fixed center background-darkgrey">
          <div class="cols col-1 col1 tablet-full-width">
            <div class="block full-width container-fixed float-left pad-40 padding grid">

              <!--grid-->
              <div class="row" id="work" data-sidenav="work">
                <comp-masonrygrid #masonryGridComp [parent]="t" [el]="content.fields[2]" [eid]="0" [id]="0" [rid]="0" [pages]="null" [attrDir]="content.fields[2].attributes" [comp]="masonryGridComp" [noDir]="true"  (callback)="handleCallback($event)"></comp-masonrygrid>
              </div>

              <!--navigation -->
              <!-- <div class="pad-vert-60 maxwidth-1000 table width-80 center full-width text-primary-section scroll-in scroll-fade">
                <div (click)="navPage('prev')" class="pointer cta-circle cta primary primary-hover abs left"><div class="cta-wrap"><div class="title">< Previous</div></div></div>
                <div (click)="changePage('our-work')" class="pointer cta-circle cta primary primary-hover abs center"><div class="cta-wrap"><div class="title">All Projects</div></div></div>
                <div (click)="navPage('next')" class="pointer cta-circle cta primary primary-hover abs right"><div class="cta-wrap"><div class="title">Next ></div></div></div>
              </div> -->

            </div>
          </div>
      </div>


      <!--testimonials -->
      <div id="row3" *ngIf="content.fields[3].value !== ''" class="casestudy-item row row1 marg-vert-40 rel v-align-parent container-fixed center background-tertiary text-white-section">
         <div class="cols col-1 col1 tablet-full-width">
           <div class="block container-fixed full-width float-left n pad-40 padding">

             <div class="row mainrow" id="testimonial" class="center" *ngIf="content.fields.length >= 4">
               <div *ngFor="let tt of content.fields[3].value | format: 'json'">
                <div class="testimonial-wrap wrap center v-align desktop-width-70 table">
                    <div id="testimonialContent">
                      {{tt.desc}}
                     </div>
                     <div id="testimonialTitle">
                       {{tt.title}}
                     </div>
                 </div>
               </div>

             </div>

           </div>
         </div>
      </div>





      <!--related projects-->
      <div id="row4" class="casestudy-item row row1 marg-vert-40 rel v-align-parent container-fixed center">
         <div class="cols col-1 col1 tablet-full-width">
           <div class="block container-fixed full-width float-left n pad-40 padding">

             <div [ngClass]="relatedCaseStudies >= 1 ? '' : 'hide'" class="row" id="related" data-sidenav="related" class="pad-top-40">
               <h2 class="text-tertiary">Related projects</h2>
               <comp-masonrygrid #masonryGridComp [suffix]="'related'" [parent]="t" [el]="content.fields[2]" [related]="true" [filter]="content.fields[5].value" [eid]="0" [id]="0" [rid]="0" [pages]="null" [attrDir]="content.fields[2].attributes" [comp]="masonryGridComp" [noDir]="true"  (callback)="handleCallback($event)"></comp-masonrygrid>
             </div>

           </div>
         </div>
      </div>


      <!--navigation-->
      <div id="row5" class="panel-bottom casestudy-item row row1 marg-vert-40 rel v-align-parent container-fixed center">
         <div class="cols col-1 col1 tablet-full-width">
           <div class="block container-fixed full-width table pad-40 padding">

             <div class="pad-vert-60 maxwidth-1000 table width-80 center full-width text-primary-section scroll-in scroll-fade">
               <div (click)="navPage('prev')" class="pointer abs left v-align2"><img src="./assets/icons/arrow-down-primary.svg" class="block rotate-90cc" width="60" height="60" /></div>
               <div (click)="changePage('our-work')" class="pointer cta-circle cta primary primary-hover abs center v-align2"><div class="cta-wrap"><div class="title">All Projects</div></div></div>
               <div (click)="navPage('next')" class="pointer abs right v-align2"><img src="./assets/icons/arrow-down-primary.svg" class="block rotate-90" width="60" height="60" /></div>
             </div>

           </div>
         </div>
      </div>




      <!-- interest popup -->
      <!-- <div *ngIf="popupInterest" id="popupInterest" class="background-white v-align2 circle center pad-80">

        <div *ngIf="!popupInterestForm && !popupInterestFormSubmitted" class="intro-wrap">
          <h1>Updates...</h1>
          <p>Looks like you’ve viewed a few projects relating to the <strong>{{t.sectorInterest}}</strong> sector. Would you like us to keep you informed of any additional related projects?</p>
          <div class="buttons-wrap">
            <div class="cta-circle primary pointer primary-hover">
              <div class="cta-wrap">
                <div (click)="showInterest(1)">Yes please ></div>
              </div>
            </div>
            <div class="cta-circle primary pointer primary-hover">
              <div class="cta-wrap">
                <div (click)="showInterest(2)">Not this time ></div>
              </div>
            </div>
            <div class="cta-circle primary pointer primary-hover">
              <div class="cta-wrap">
                <div (click)="showInterest(3)">No thanks ></div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="popupInterestForm && !popupInterestFormSubmitted" class="interest-form">
          <h1>Thanks!</h1>
          <p>Fill in your address below and we’ll keep you up to date!  All information will be treated in accordance with our Privacy Policy ></p>
          <input type="text" placeholder="Enter your email address" value="" [(ngModel)]="interestEmail" />
          <div (click)="submitInterest()" class="cta-circle primary pointer primary-hover">
            <div class="cta-wrap">
              <div>Confirm ></div>
            </div>
          </div>
        </div>

        <div *ngIf="popupInterestFormSubmitted" class="form-submitted">
          <h1>Success!</h1>
          <p>You can unsubscribe from our updates at any time by clicking the unsubscribe link, or emailing us at <a href="mailto:talk@chaseandcoadvertising.co.uk" class="bold">talk@chaseandcoadvertising.co.uk</a></p>
          <div (click)="popupInterest = false" class="cta-circle primary pointer primary-hover">
            <div class="cta-wrap">
              <div>Back to Projects ></div>
            </div>
          </div>
        </div>

      </div> -->

      <!-- social share -->
      <comp-socialshare (callback)="handleCallback($event)"></comp-socialshare>


      <div [ngClass]="popupInterest ? 'show': 'hide'" id="parallax-scene" class="parallax-scene circle-background" dataParallaxDir ></div>
