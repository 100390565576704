import { Component, Input, Output, EventEmitter, OnInit, QueryList, ViewEncapsulation, ViewChildren, ViewChild,SimpleChanges  } from '@angular/core';
import { Injectable } from '@angular/core';
import {Library} from '../../app/app.library';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';

//Call specific necessary components here
import { MasonryGridComponent } from '../MasonryGridComp/masonrygrid.component';

@Component({
  selector: 'comp-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
  providers:[Library],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent {

  @ViewChildren('masonryGridComp') grids: QueryList<MasonryGridComponent>;
  private MasonryGrid: MasonryGridComponent;

  @Input() el: any;
  @Input() idx: any;
  @Input() eid: any;
  @Input() g: any = '';
  @Input() parent: any;
  @Output() callback = new EventEmitter();

  showinfowindow:boolean = false;
  infoitem:any = {};
  infoidx:number = 0;
  profileidx:number = -1;
  infolist:any;
  sublist:any = '';
  subid:number = 0;
  filterTag: string = 'service';

  constructor(public lib: Library,private http: HttpClient){}


    ngOnInit(){
      this.loadInfoWindow();
    }

    ngAfterContentInit(){}

    ngOnChanges (changes: SimpleChanges) {
      if(this.g.length){
        //update infolist if get value is present
        this.infoListCheck();
      }
    } // end onChanges

    /* Callback */

    doCallback(a,p){
      this.callback.emit({'a':a,'p':p});
    }

    /*Info window Functions */

    infoListCheck(){
      if(this.el.classes.includes('info-list')){
        JSON.parse(this.el.value).forEach((itm,i) => {
          if(itm.title.toLowerCase().indexOf(this.g) !== -1){
            this.infoidx = i;
            let item = itm;
            let idx = i;
            let infolist = JSON.parse(this.el.value);
            this.showInfoWindow(infolist,item,idx);
          }
        });
      }
    }


    loadInfoWindow(){
      //if child element is a list that uses info module
      if(this.el.classes.includes('info-list')){
        //configure query parameter as alias and remove hashtag if any

        //convert get value to string if boolean
        if(!this.g) this.g = '';
        //console.log(JSON.parse(this.el.value)[0]);
        let alias = this.g.indexOf('#')  !== -1 ? this.g.split('#')[1] : this.g;
        let idx = 0;

        //Loop through list items contained in element value
        JSON.parse(this.el.value).forEach((itm,i) => {

           //Change title to alias format (lower case and replace spaces with hiphen)
           let title = itm.title.toLowerCase().replace(/\W+/g, '-');

           //if converted title matches alias, set as index of info item
           if(title.includes(alias) && alias.length > 0) idx = i;

           //Do sublists
           itm.sublist.forEach((subitm,si) => {
              //Same process for subtitle as for title
              let subtitle = subitm.title.toLowerCase().replace(/\W+/g, '-');
              if(subtitle.includes(alias) && alias.length > 0) idx = i;
           });

           //Confirm id matches with index (not sure if this is needed)
           console.log(idx);
           if(i == idx){
             //Set information item
             this.infoidx = (i+1);
             let item = JSON.parse(this.el.value)[idx];
             let infolist = JSON.parse(this.el.value);
             //Show info window
             this.showInfoWindow(infolist,item,idx);
           }

        });
      }
    }


    showInfoWindow(infolist:any = false,item,idx){
      this.showinfowindow = false;
      setTimeout(()=>{
        this.showinfowindow = true;
        this.infoitem = item;
        this.infoidx = idx;
        if(item.sublist){
          setTimeout(()=>{
            this.subid = 0;
            this.sublist = item.sublist[0].desc;
          },200);
        }
      },200);
      this.showProfileDesc();
    }

    closeInfoWindow(){
      this.showinfowindow = false;
    }


    /*Profile Window functions */

    showProfileWindow(infolist,item,idx,reveal:any=false){
      if(this.profileidx == idx){
        this.profileidx = -1;
        var tt = document.getElementById("infotextcontainer");
        tt.setAttribute('style','opacity:0px');
        return false;
      } else{
        this.parent.profilewindow = reveal;
        this.parent.profile = item;
        this.showinfowindow = false;
        setTimeout(()=>{
          this.showinfowindow = true;
          this.infoitem = item;
          this.profileidx = idx;
          if(item.sublist){
            this.subid = 0;
            this.sublist = item.sublist[0].desc;
          }
        },200);
        this.showProfileDesc();
      }
    }

    showProfileDesc(){
      if(!this.lib.isObjectEmpty(this.parent.profile)){
        let profile = this.parent.profile;
        var tt = document.getElementById("infotextcontainer");
        tt.setAttribute('style','opacity:0px; top:' + tt.offsetTop + 'px');
        setTimeout(() => {
              let posY = this.lib.getElementY2(this.parent.profilewindow);
              tt.innerHTML = '<p><a href="'+profile.email+'">'+profile.email+'</a><br>'+profile.desc+'</p>';
              tt.setAttribute('style','top:'+posY+'px; opacity:1');
        },800);
      }
    }


    /*Tag, filtering functions */

    filterTags(li:any = false){
      this.parent.alreadyscrolled = true;
      if(li == this.parent.tagfocus) { this.parent.tagfocus = ''; li = false; }
      this.parent.tagfocus = li;
      let params = {"alias": null, "action": "filtergrid", "event": false, "get": li};
      //this.callback.emit(params);
      this.parent.handleCallback(params);
    }

    toggleTagList(sector){
      this.filterTag = sector;
    }

    toggleReveal(div){
      let classes = div.classList;
      !classes.contains('toggle-reveal') ? classes.add('toggle-reveal') : classes.remove('toggle-reveal');
    }

    selectSubList(itm,sid){
      this.sublist = '';
      this.subid = sid;
      setTimeout(()=>{
        this.sublist = itm.desc;
      },200);
    }


}
