import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {Library} from '../../app/app.library';

@Component({
  selector: 'comp-masonrygrid',
  templateUrl: 'masonrygrid.component.html',
  styleUrls: ['masonrygrid.component.scss'],
  providers:[Library]
})
export class MasonryGridComponent implements OnInit {

  @Input() pages: any;
  @Input() setPage: string;
  @Input() el: any;
  @Input() eid: number;
  @Input() idx: number;
  @Input() rid: any;
  @Input() masonryGrid;
  @Input() parent;
  @Input() filter:any = false;
  @Input() related:any = false;
  @Input() suffix:string = '';
  @Input() casestudy: string = '';
  @Output() callback = new EventEmitter();

  timeout:number = 500;
  resized:any;
  list:any = [];
  fulllist:any = [];
  filterlist:any = [];
  grid:any = [];
  newcol:any = {'images':[],'coltype':'','isFull':false};
  newrow:any = {'cols':[],'isFull':false, 'colsFull': 0 };
  maxcols:number = 4;
  selectrow:any;
  selectcol:any;
  masonry;
  masonryEl:any;
  random:boolean = true;
  automated:boolean = true;
  win;
  do:any;
  limitDefault:number = 100;
  limit:number = 100;
  start:number = 0;
  end:number = 0;
  loadFinished = false;
  masonryLoaderAlias:string = '';
  masonryGridAlias:string = '';

  constructor(public lib: Library) {
    this.do = this.doMasonry;
  }

  //This gets triggered before ngOnInit and during changes
  async ngOnChanges() {

    //Set default parameters
    this.limit = this.limitDefault;
    this.start = 0;
    this.loadFinished = false;
    //Check for case study attribute
    this.checkForCaseStudy();

    //Declare the grid elements
    this.masonryLoaderAlias = 'masonry-grid-loader-'+this.suffix;
    this.masonryGridAlias = 'masonry-grid-'+this.suffix;
    setTimeout(() => {
      this.masonry = document.getElementById(this.masonryLoaderAlias);
      this.masonryEl = document.getElementById(this.masonryGridAlias);
    },);

    //Begin loading data as a list
    await this.doList(this.el.value);
    //Once list is loaded, display onto masonry grid
    await this.doMasonry(this.masonry,this.parent.tag);
    //If there are any filters, do related case studies
    if(!!this.filter){
      this.doRelated();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.checkForCaseStudy();

    if(!!this.filter){
      this.doRelated();
    }
  }

  /* Check for case studies */

  async checkForCaseStudy(){
    const attr = this.el.attributes;
    if(attr){
      let attrs = attr.split(',');
      attrs.forEach(at => {
        let a = at.split('=');
        let k = a[0];
        let v = a[1];
        if(k == 'casestudy') this.casestudy = v;
      });
    }
  }

  /* Related case studies */

  async doRelated(){
    console.log('loading related case studies');
    this.maxcols = 3;
    this.limit = 3;
    this.casestudy = '';
    let wholeGrid = this.parent.pages['our-work'].fields[0].value;
    await this.doList(wholeGrid);
    await this.filterTags(this.filter);
  }

  /* Main masonry function */

  async doMasonry(masonry,filter:any=false){

    clearTimeout(this.resized);

    if(this.masonryEl) this.masonryEl.classList.add('hide2');
    let _t = this;

    if(masonry){
    this.resized = setTimeout(function(){

      //check if random mode is disabled
      if(masonry.classList.contains('not-random')) _t.random = false;
      if(masonry.classList.contains('not-automated')) _t.automated = false;

      let children = masonry.children;
      let mh = masonry.clientHeight;
      let mw = masonry.clientWidth;

      //if start is above 0, skip
      if(_t.start > 0){
        children = Array.from(children).splice(_t.start,_t.limit);
      } else {
        _t.grid = [];
      }

      Array.from(children).forEach(async (child:any,i) => {

        //prevent from loading if limit reached
        if(i >= _t.limit) {
          return false;
        }

        let ch = child.clientHeight;
        let cw = child.clientWidth;
        let origtype = child.classList[0];
        let img = child.querySelectorAll('.image')[0];

        let image = _t.createImage(img,origtype,i);
        let tags = image.tags.split(',');
        let allow = true; //by default allow all children to built into grid

        //if there is a filter, check child tags
        if(!!filter) {
          //if filter cant be found in tags, skip this child
          if(tags.indexOf(filter) === -1) allow = false;
        }

        //allow child to be built, if tags contains filter
        if(allow){

        if(_t.grid.length == 0){

          //if grid rows is empty, create new row, column and place first image
          _t.createNew(image);
          //then check column
          _t.checkCol();
          //check row
          _t.checkRow();

        } else {

          //if grid is not empty, check last row
          _t.selectRow();
          //check row
          _t.checkRow();

          //if row is full

          if(_t.selectrow.isFull){

            //create new row and place image in first column
            _t.createNew(image);

          } else {

              //if row is not full
              //check is last column is full

              let col = _t.selectcol;

              //is column full, prep new one for placement
              if(col.isFull === true) _t.prepPlaceNewCol(image.type);

              //once determined and prepared, add image
              _t.placeImage(image);

              //then check column
              _t.checkCol();

              //check row
              _t.checkRow();

          } //if select row is full

        } // if grid is empty

      } // allow image

      }); //end looping through children images

      //now clean up grid and fix any incomplete columns
      _t.cleanUp();

      _t.parent.gridLoaded = true;

      _t.masonryEl.classList.remove('hide2');

    }, this.timeout);
    }//if masonry
  }

  /* Create Image */

  createImage(img,origtype:any = false,i:number = 0){
    let ih = img.height;
    let iw = img.width;
    let src = img.src;
    let link = img.attributes['data-link'];
    let tags = img.attributes['data-tags'];
    let title = img.attributes['data-title'];
    let desc = img.attributes['data-desc'];
    let content = img.attributes['data-content'];
    let animation = img.attributes['data-animation'];
    let video = img.attributes['data-video'];
    let videocontrols = img.attributes['data-videocontrols'];

    if(this.start > 0 ) i = (this.start + i);
    if(this.related == false) if(this.casestudy.length) src = this.automated == true ? './assets/images/case-studies/'+this.casestudy+'/casestudy-'+this.casestudy+'-'+(i+1)+'.jpg' : img.src;

    let type = ih == iw ? 'square' : ih > iw ? 'portrait' : 'horiz';
    let t = !!origtype ? origtype : type;
    if(this.related) t = 'related';
    return { type: t, src: src, width: iw, height: ih, link: link.value, tags: tags.value, title : title.value, desc: desc.value, content:content.value, animation: animation.value, video: video.value, videocontrols: videocontrols.value == 'true' }
  }

  /* Create a new row */

  createRow(){
    this.grid.push(this.lib.deepCopy(this.newrow));
    this.selectRow();
  }

  /* Determine if row is full */

  isRowFull(){
    return this.selectrow.colsFull < this.maxcols;
  }

  /* Check if row is complete - Has maximum number of columns */

  checkRow(){
    if(this.selectrow.colsFull >= this.maxcols) this.selectrow.isFull = true;
  }

  /* Select row */

  selectRow(idx:any = false){
    idx = idx == false ? this.grid.length-1 : idx;
    this.selectrow = this.grid[idx];
  }

  /* Create Column */

  createCol(){
    this.selectrow.cols.push(this.lib.deepCopy(this.newcol));
    this.selectcol = this.selectrow.cols[this.selectrow.cols.length-1];
  }

  /* Check if column is complete */

  checkCol(){
    let c = this.selectcol;
    if(c.images.length == 2 && c.coltype == 'halfsquare') c.isFull = true;
    if(c.images.length == 4 && c.coltype == 'quartersquare') c.isFull = true;
    if(c.images.length == 3 && c.coltype == 'related') c.isFull = true;
    if(c.images.length == 3 && c.coltype == 'third') c.isFull = true;
  }

  /* Prepare for new column */

  prepPlaceNewCol(origcoltype:any = false){
    //determine how many columns remain and what types are possible
    let colremain =  this.maxcols - this.selectrow.colsFull;

    let options = [];
    let isFull = false;

    //determine coltype based on how many colsremain (override image type)
    switch(colremain){
      case 1:
        options = ['portrait','halfsquare'];
      break;
      case 2:
        options = ['fullsquare','quartersquare', 'halfsquare', 'portrait', 'third'];
      break;
      case 3:
        options = ['horiz', 'portrait', 'fullsquare', 'halfsquare', 'quartersquare', 'third'];
      break;
    }

    let coltype = this.random == true ? this.lib.randomChoice(options) : origcoltype; //coltype determined by how many cols remain
    if(this.related) coltype = 'related';

    //determine isfull
    switch(coltype){
      case 'related':
      case 'third':
      case 'portrait':
      case 'horiz':
      case 'fullsquare':
       isFull = true;
      break;
    }

    //add new column
    if(options.includes(coltype) == false && !this.related){
      //if coltype is not in options, create a new row as type will not fit on current row
      this.createRow();
      this.createCol();
    } else {
      this.createCol();
    }

    this.selectcol.isFull = isFull;
    this.selectcol.coltype = coltype;

  }

  /* Create new grid element */

  createNew(image){
    if(this.related) image.type = 'related';
    this.createRow();
    this.createCol();
    let coltype = image.type;
    let isfull = true;
    let colsfull = 1;
    switch(image.type){
      case 'related':
        colsfull = 1;
      break;
      case 'horiz':
        colsfull = 4;
      break;
      case 'third':
        colsfull = 1;
      break;
      case 'square':
        coltype = this.random == true ? this.lib.randomChoice(['fullsquare', 'halfsquare', 'quartersquare']) : image.type;
        switch(coltype){
          case 'fullsquare':
            colsfull = 2;
          break;
          case 'halfsquare':
            isfull = false;
            colsfull = 0.5;
          break;
          case 'quartersquare':
            isfull = false;
            colsfull = 0.25;
          break;
        }
      break;
      case 'fullsquare':
        colsfull = 2;
      break;
      case 'halfsquare':
        isfull = false;
        colsfull = 0.5;
      break;
      case 'quartersquare':
        isfull = false;
        colsfull = 0.25;
      break;
    }
    this.selectcol.coltype = coltype;
    this.selectcol.isFull = isfull;
    this.selectcol.images.push(image);
    this.selectrow.colsFull += colsfull;
    this.checkRow();
  }

  /* Place image in select grid element */

  placeImage(image){
    //update colsFull count
    let val = 0;
    switch(this.selectcol.coltype){
      case 'quartersquare': val = 0.25; break;
      case 'halfsquare': val = 0.5; break;
      case 'portrait': val = 1; break;
      case 'fullsquare': val = 2; break;
      case 'horiz': val = 3; break;
      case 'related': val = 1; break;
      case 'third': val = 1; break;
    }
    this.selectrow.colsFull += val;

    //add the image
    this.selectcol.images.push(image);

  }

  /* Clean up grid and handle types and sizes */

  cleanUp(){
    this.grid.forEach(row =>{
      row.cols.forEach(col =>{

          //Handle if colum isn't full
          if(!col.isFull){

            //First, assess what column type it is
            switch(col.coltype){

              //if column type is half square and only has one image, convert to potrait
              case 'halfsquare':
                if(col.images.length > 1){
                  col.images.forEach(image => {
                    image.type = 'halfsquare';
                  });
                } else {
                  col.coltype = 'portrait';
                }
              break;

              //if quarter square, count how many
              case 'quartersquare':

                switch(col.images.length){

                  //if three
                  case 3:

                    //take last image from row and convert to 'horiz' type
                    let lastimage = col.images[col.images.length-1];
                    lastimage.type = 'horiz';
                    col.images.pop();

                    //convert column type and remaining images from quarter to half square
                    if(row.cols.length > 1){
                      col.coltype = 'halfsquare';
                      col.images.forEach(image => {
                        image.type = 'halfsquare';
                      });
                    }

                    //then add third item onto new row / column
                    this.createNew(lastimage);
                  break;

                  //if two
                  case 2:

                  //if row has more than one column, and if column has only 2 quarters, change to half
                  if(row.cols.length > 1){
                    col.coltype = 'halfsquare';
                    col.images.forEach(image => {
                      image.type = 'halfsquare';
                    });
                  }
                  break;

                  //if only one, convert to fullsquare
                  case 1:
                    col.coltype = 'fullsquare';
                  break;
                }
              break;
            }

            col.isFull = true;
          }
      });
    });
  }

  /* Handle masonry during window resize */

  resizeMasonry(){

      //Set parameters and sizes here
      let mobilesize = 600;
      let tabletsize = 899;
      let desktopsize = 1200;
      let windowsize = window.outerWidth;
      let newwin = '';

      //Set specific maximum column limit depending on window size here
      if(windowsize <= mobilesize ){ newwin = 'mobile'; this.maxcols = 4; }
      if(windowsize > mobilesize && windowsize < desktopsize ){ newwin = 'tablet'; this.maxcols = 4; }
      if(windowsize >= desktopsize ){ newwin = 'desktop'; this.maxcols = 4; }

      //Reload masonry if new window dimensions
      if(this.win !== newwin){
        this.win = newwin;
        this.doMasonry(this.masonry,this.parent.tag);
      }
  }

  /* Handle filter tags */

  async filterTags(filter:any = this.parent.tag){

    //If filter is not false
    if(!!filter){

      //First format it
      filter = this.formatFilter(filter);
      //Set default parameters
      this.start = 0;
      this.filterlist = !filter ? this.fulllist : this.lib.deepCopy(this.fulllist.filter(r => r.tags.indexOf(filter) !== -1));
      this.limit = !this.related ? this.filterlist.length <= this.limitDefault ? this.filterlist.length : this.limitDefault : this.limit;
      this.list = this.lib.deepCopy(this.filterlist).splice(0,this.limit);

      //If filtered list is less then limit, finish loading
      if(this.filterlist.length <= this.limit){
        this.loadFinished = true;
        this.limit = this.filterlist.length;
      } else {
        //Else reset defaults
        this.limit = this.limitDefault;
        this.loadFinished = false;
      }
    } else {
      //If not filter, set defaults
      this.start = 0;
      this.limit = this.limitDefault;
      this.filterlist = this.lib.deepCopy(this.fulllist);
      this.list = this.lib.deepCopy(this.filterlist).splice(0,this.limit);
    }

    //Once configured, load masonry with filtered list
    await this.doMasonry(this.masonry,filter);
  }

  test(){
    console.log('test');
  }

  /* Callbacks */

  doCallback(a,p){
    this.callback.emit({'action':a,'a':a,'p':p});
  }

  loadMore(){
    //Calculate start and end of amount of projects to load, if any
    this.start = this.list.length;
    this.end = (this.limitDefault + this.list.length) >= this.filterlist.length ? (this.filterlist.length - this.list.length) : this.limitDefault;
    //Make a copy of list
    let l = this.lib.deepCopy(this.filterlist);
    //Select items
    let nl = l.splice(this.start, this.end);
    //Add items to main list
    this.list = this.list.concat(nl);
    //Re-determine if load is finished
    this.loadFinished = this.list.length == this.filterlist.length ? true : false;
    //Re-init masonry with updated list
    this.masonry = document.getElementById(this.masonryLoaderAlias);
    this.doMasonry(this.masonry,this.parent.tag);

  }

  /* Format List */

  doList(list){

    //Format list, determine if it is JSON or string
    if(typeof list == 'string'){
      list = list.replace(/'/g,'"');
      list = list.replace(/\/"/g,'\'');
      list = JSON.parse(list);
    }

    //If on the work page, flip the list - TO DO: Maybe make as 'reverse-list' class on list element
    if(this.parent.setPage == 'our-work') list = list.reverse();

    //Set list variables
    this.fulllist = this.lib.deepCopy(list);
    if(this.related) this.fulllist = this.fulllist.filter(r => r.link !== this.parent.setPage);
    this.filterlist = this.lib.deepCopy(this.fulllist);
    this.list = list.splice(0,this.limit);

    //Check if grid is finished loading
    this.checkGridLoaded();

    return this.list;
  }

  /* Check if grid has finished loading */

  checkGridLoaded(){
    this.loadFinished = this.list.length == this.filterlist.length ? true : false;
  }

  /* Format filter tags - replace ampersand and spaces with hyphens */

  formatFilter(v){
    if(v.includes('&')){
       v = v.replace(' & ', '-');
    } else if(v.includes(' ')) {
       v = v.replace(/\s+/g, '-');
    }
    return v;
  }

}
