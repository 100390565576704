
       <div id="row1" class="casestudy-item row row1 marg-vert-40 rel v-align-parent container-fixed center">
          <div class="cols col-1 col1 tablet-full-width">
            <div class="block container-fixed maxwidth-1000 center text-left pad-40 padding">

              <!--breadcrumbs-->
              <!-- <div class="element row-1-col-2-el-0">
                <div class="element-wrapper">
                  <div (click)="t.changePage('news')">Blog ></div>
                </div>
              </div> -->

              <!--heading-->
              <!-- <div class="element mainrow row-1-col-2-el-0 scroll-in scroll-once">
                <div class="element-wrapper">
                  <h1 class="heading"  [innerHTML]="content.fields[0].value"></h1>
                </div>
              </div> -->

              <!--profile-->
              <div class="element row-1-col-2-el-0 scroll-in scroll-once">
                <div class="element-wrapper">
                  <div class="row pad-bot-40">
                      <div class="cols col-2 width-25">
                        <div class="cta-circle large pointer">
                          <div class="cta-wrap inline">
                            <div class="box-img-wrap rel">
                              <img class="box-img" src="./assets/images/profiles/{{content.fields[3].key}}.svg" alt="Profile Pic" class="full-width background-primary circle no-padding">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cols col-2 blog-details v-align">
                        <div class="table fill-height full-width">
                          <div class="v-align">
                            <div class="subtitle"><h5>{{content.fields[4].value}}</h5></div>
                            <p class="text-darkgrey">
                              by {{content.fields[3].value}}<br>
                              {{content.fields[1].value}}
                            </p>

                          </div>
                        </div>
                      </div>
                  </div>



                </div>
              </div>



              <!--content-->
              <div class="element mainrow row-1-col-2-el-0 scroll-in scroll-once">
                <div class="element-wrapper">

                    <div *ngFor="let item of content.fields[2].value | format : 'json'" class="row container">
                      <div *ngIf="item.image" class="image-wrap">
                        <img src="{{item.image}}" width="100%" height="auto" style="display:block" class="image" />
                      </div>
                      <div class="subtitle"><h5>{{item.subtitle}}</h5></div>
                      <div class="content" [innerHTML]="item.content"></div>
                    </div>

                </div>
              </div>



            </div>
          </div>
      </div>
      <div class="row" style="border-top:solid 40px #9EE6EF">
        <div class="col">
          <!--end -->
          <div style="height:200px" class="element pad-vert-60 table center full-width text-primary-section scroll-in scroll-fade scroll-once">

            <h5>Keep up with us socially:</h5>

            <div class="row footer-icons social-icons v-align-bot pad-top-20">
              <a href="https://www.facebook.com/wearegraphici" target="_blank"><img src="assets/icons/icon_facebook_circle_primary.svg" class="social-icon" /></a>
              <a href="https://www.instagram.com/wearegraphici/" target="_blank"><img src="assets/icons/icon_instagram_circle_primary.svg" class="social-icon" /></a>
              <a href="https://twitter.com/wearegraphici" target="_blank"><img src="assets/icons/icon_twitter_circle_primary.svg" class="social-icon" /></a>
              <a href="https://www.linkedin.com/company/wearegraphici" target="_blank"><img src="assets/icons/icon_linkedin_circle_primary.svg" class="social-icon" /></a>
            </div>
          </div>

          </div>
      </div>
