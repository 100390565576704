<div class="sidenav-panel share-panel right v-align2" [ngClass]="onRight ? 'on': ''">

  <div class="share-wrap v-align2 abs table">
    <div class="share-button background-primary v-align2 abs">
      <div class="rotate-90cc">
        Share >
      </div>
    </div>

    <div class="share-section v-align2">
      <div class="col social-icons v-align2">
        <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{fullurl}}"><img src="assets/icons/icon_facebook.svg" class="social-icon"></a>
        <!-- <img src="assets/icons/icon_instagram.svg" class="social-icon"> -->
        <a target="_blank" href="http://twitter.com/share?url={{fullurl}}"><img src="assets/icons/icon_twitter.svg" class="social-icon"></a>
        <a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url={{fullurl}}"><img src="assets/icons/icon_linkedin.svg" class="social-icon"></a>
      </div>
    </div>
  </div>

</div>


<div class="sidenav-panel left v-align2" [ngClass]="onLeft ? 'on': ''">
  <ul class="sidenav-list v-align2 abs">
      <li *ngFor="let route of routes; let idx = index" class="nav-item" (click)="navigateRoute(route.id,idx,$event)" [ngClass]="idx == activeitem ? 'on': ''"></li>
  </ul>
</div>
