import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {Library} from '../../app/app.library';

@Component({
  selector: 'comp-sidenav',
  templateUrl: 'sidenav.component.html',
  styleUrls: ['sidenav.component.scss'],
  providers:[Library]
})
export class SideNavComponent implements OnInit {

  onLeft:boolean = false;
  onRight:boolean = false;
  activeitem:number = 0;
  routes:any;
  fullurl:string = '';
  @Output() callback = new EventEmitter();

  constructor(public lib: Library) {
    this.fullurl = window.location.href;
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.iniSideNav();
  }

  ngOnChanges() {
    //Initiate side nav if mouse is hovering on the left or right side of the window
    if(this.onLeft || this.onRight){
      this.iniSideNav();
    }
  }

  close() {
  }

  emit(params){
    this.callback.emit(params);
  }

  //Initiate side nav
  iniSideNav(){
    //Get list of row elements on the page
    let rows = document.querySelectorAll('.row');
    this.routes = [];
    //For each row
    rows.forEach(row => {
      //Detect if there is a side nav attribute
      if(row.hasAttribute('data-sidenav')){
        //If there is grab the data
        let attr = row.getAttribute('data-sidenav');
        let icon = row.hasAttribute('data-sidenavicon') ? row.getAttribute('data-sidenavicon') : '';
        let id = row.getAttribute('id');
        //Create a route
        let route = {
          'id': id,
          'label': attr,
          'icon': icon
        }
        //Add to routes array
        this.routes.push(route);
      }
    });
  }

  //Navigate to a route
  navigateRoute(id,idx,event){
    //Set active item
    this.activeitem = idx;
    //Set params for scrolling to route element
    let params = {"alias": id, "action": "scrollto", "event": event, "get": false};
    //Scroll to element via parent callback function with 'scrollto' action
    this.emit(params);
  }

}
