<div [@search] *ngIf="visible" class="search-panel">

  <div class="search-panel-wrap fill-height center width-70 text-left" style="background-color: #fbfbfb;max-width:1000px">

  <button *ngIf="closable" (click)="close()" aria-label="Close" class="search__close-btn">X Close</button>

  <div class="search_panel" style="background-color: #efefef;">
    <div class="wrap">

      <h4>Search</h4>

      <input type="text" [(ngModel)]="query" (ngModelChange)="querySearch()" placeholder="Enter your query here" />

   </div>
  </div>

  <div class="wrap">
  <div class="row full-width">

  <div class="cols feed-filter-col">

    <!-- <div *ngIf="el.show_sort" class="sortbox filterbox">
      <h4>Sort</h4>
      <select [(ngModel)]="sort" (ngModelChange)="sortFeed()">
     <option [ngValue]="opt" *ngFor="let opt of sort_options">{{opt.value}}</option>
   </select>
    </div> -->

    <div class="groupbox filterbox">
      <h4>Groups</h4>

      <!-- <div *ngFor="let group of groups" class="group">
        <input type="checkbox" name="{{ group | format : 'string' }}" checked="{{ groups_select &amp;&amp; -1 !== groups_select.indexOf(group) ? 'checked' : '' }}" (change)="filterSelection($event,'groups_select')" />
        <span class="maintag bold">{{group | format : 'string'}}</span>
      </div> -->


      <div class="group">
        <input type="checkbox" name="service-page" checked="{{ groups_select &amp;&amp; -1 !== groups_select.indexOf(groups) ? 'checked' : '' }}" (change)="filterSelection($event,'groups_select')" />
        <span class="maingroup bold">Service</span>
      </div>
      <div class="group">
        <input type="checkbox" name="info-page" checked="{{ groups_select &amp;&amp; -1 !== groups_select.indexOf(groups) ? 'checked' : '' }}" (change)="filterSelection($event,'groups_select')" />
        <span class="maingroup bold">Information</span>
      </div>
      <div class="group">
        <input type="checkbox" name="news-page" checked="{{ groups_select &amp;&amp; -1 !== groups_select.indexOf(groups) ? 'checked' : '' }}" (change)="filterSelection($event,'groups_select')" />
        <span class="maingroup bold">News</span>
      </div>
    </div>

    <div class="tagbox filterbox">
      <h4>Tags</h4>
      <div *ngFor="let tag of tags | keys" class="tag">
        <div *ngIf="lib.objLength(tag.value.subtags) == 0" class="maintag">
          <input type="checkbox" name="{{ tag.key | format : 'string' }}" checked="{{ filters &amp;&amp; -1 !== filters.indexOf(tag.key) ? 'checked' : '' }}" (change)="filterSelection($event,'filters')" />
          <span class="maintag bold">{{tag.key | format : 'string'}}</span> ({{tag.value.count}})
        </div>
        <div *ngIf="lib.objLength(tag.value.subtags) > 0" class="subtags">
          <input type="checkbox" name="{{ tag.key | format : 'string' }}" checked="{{ filters &amp;&amp; -1 !== filters.indexOf(tag.key) ? 'checked' : '' }}" (change)="filterSelection($event,'filters')" />
          <span class="maintag bold">{{tag.key | format : 'string'}}</span> ({{tag.value.count}})
          <div *ngFor="let subtag of tag.value.subtags | keys" class="subtag">
            <input type="checkbox" name="{{ subtag.key | format : 'string' }}" checked="{{ filters &amp;&amp; -1 !== filters.indexOf(subtag.key) ? 'checked' : '' }}" (change)="filterSelection($event,'filters')" /> {{subtag.key}} ({{subtag.value.count}})
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="cols feed-list-col">

    <!-- pagination -->
    <!--
    <div *ngIf="pagination.length > 1" class="top_pagination_wrapper pagination_wrapper row" style="padding: 0;
    width: 77.4%;position:fixed; margin:0px;">
    <div class="pag-wrap" style="padding-top: 40px;">
        <div>
          <div class="col pag-controls pag-control-start">
            <div class="float-left">
              <button (click)="setPagIndex(0)">First</button>
              <button (click)="navPag(0)">Prev</button>
            </div>
          </div>
          <div class="col pag-list">
            <ul>
              <li *ngFor="let pag of pagination;let pid = index" (click)="setPagIndex(pid)">
                <span [ngClass]="pagindex == pid ? 'active': ''">{{pid+1}}</span>
              </li>
            </ul>
          </div>
          <div class="col pag-controls pag-control-end">
            <div class="float-right">
              <button (click)="navPag(1)">Next</button>
              <button (click)="setPagIndex(pagination.length-1)">Last</button>
            </div>
          </div>
        </div>
          </div>
      </div> -->

      <div class="feed-list-wrap">
        <div *ngFor="let page of pagination[pagindex]; let pidx = index" class="search-row row-{{pidx}}">
          <div *ngIf="page.value.published" class="item-wrapper">
            <div class="row full-width">
              <h3 class="bold menulink" (click)="handleCallback('changePage',[page.value.alias,0,[],$event,false])">{{page.value.title }}</h3>
              <p *ngIf="page.value.meta.description.length" class="marg-vert-20">{{page.value.meta.description }}</p>
              <a (click)="handleCallback('changePage',[page.value.alias,0,[],$event,false])" class="menulink">More</a>
            </div>
          </div>
        </div>
      </div>

      <!-- pagination -->

      <div *ngIf="pagination.length > 1" class="pagination_wrapper row">
        <div>
          <div class="col pag-controls pag-control-start">
            <div class="float-left">
              <button (click)="setPagIndex(0)">First</button>
              <button (click)="navPag(0)">Prev</button>
            </div>
          </div>
          <div class="col pag-list">
            <ul>
              <li *ngFor="let pag of pagination;let pid = index" (click)="setPagIndex(pid)">
                <span [ngClass]="pagindex == pid ? 'active': ''">{{pid+1}}</span>
              </li>
            </ul>
          </div>
          <div class="col pag-controls pag-control-end">
            <div class="float-right">
              <button (click)="navPag(1)">Next</button>
              <button (click)="setPagIndex(pagination.length-1)">Last</button>
            </div>
          </div>
        </div>
      </div>

  </div>
    </div>
  </div>
  </div>
</div>
